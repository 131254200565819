//#region Imports

import Box from '@material-ui/core/Box';
import React from 'react';

//#endregion

const TabPanel = ({ children, value, index }) => {
    return (
        <Box hidden={value !== index}>
            {value === index && <Box padding={3}>{children}</Box>}
        </Box>
    );
};

export default TabPanel;
