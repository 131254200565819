import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    button: {
        color: '#FFFFFF',
        fontFamily: 'BancoDoBrasilTitulos',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.66px',
        textAlign: 'center',
        marginTop: theme.spacing(1),
        width: '158px',
        height: '48px',
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
        },
    },
    input: {
        width: '320px',
        margin: '32px 0 38px',
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
        },
    },
}));
