import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import DoubleCardIcon from '../../assets/icons/double-card-icon-blue.svg';
import MonitorIcon from '../../assets/icons/monitor-icon-blue.svg';
import TratorIcon from '../../assets/icons/trator-icon-blue.svg';
import { useStyles } from './styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CardAccess from '../../components/CardAccess';
import ENDPOINTS from '../../consts/endpoints';
import {
    ACCESS_PORTAL_ENUM,
    ACCESS_PORTAL_TEXT,
} from '../../consts/text/form-login';
import CustomCarousel from '../../components/CustomCarousel';

export const PortalAccessCards = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('1210'));

    const { autenticacao } = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const portalItens = useMemo(
        () => [
            {
                id: ACCESS_PORTAL_ENUM.PORTAL_CORRENTISTA,
                title: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_TITLE,
                description: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_DESCRIPTION,
                btnText: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_ICON_TEXT,
                icon: DoubleCardIcon,
                href: autenticacao?.url,
                gtm: {
                    'data-gtm-type': 'click',
                    'data-gtm-clicktype': 'button',
                    id: 'acesso-bb-001',
                    'data-gtm-name': 'acesse-com-senha-bb',
                },
            },
            {
                id: ACCESS_PORTAL_ENUM.PORTAL_NAO_CORRENTISTA,
                title: ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_TITLE,
                description:
                    ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_DESCRIPTION,
                btnText: ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_ICON_TEXT,
                icon: MonitorIcon,
                showLoginForm: true,
                gtm: {
                    'data-gtm-type': 'click',
                    'data-gtm-clicktype': 'button',
                    id: 'acesse-aqui-001',
                    'data-gtm-name': 'acesse-aqui',
                },
            },
            {
                id: ACCESS_PORTAL_ENUM.SENSORIAMENTO,
                title: ACCESS_PORTAL_TEXT.SENSORIAMENTO_TITLE,
                description: ACCESS_PORTAL_TEXT.SENSORIAMENTO_DESCRIPTION,
                btnText: ACCESS_PORTAL_TEXT.SENSORIAMENTO_ICON_TEXT,
                icon: TratorIcon,
                href: ENDPOINTS.SENSORIAMENTO_LOGIN,
                gtm: {
                    'data-gtm-type': 'click',
                    'data-gtm-clicktype': 'button',
                    id: 'acesso-agro-001',
                    'data-gtm-name': 'acesso-agro',
                },
            },
        ],
        [autenticacao],
    );

    return (
        <Grid container>
            <Grid
                container
                className={classes.gridContainerPortalAccess}
                justifyContent="center"
            >
                <Grid
                    container
                    className={classes.gridMaxWidth}
                    justifyContent="space-around"
                >
                    {isMd ? (
                        <CustomCarousel
                            showDots
                            showArrows={false}
                            itemsLenght={portalItens.length}
                        >
                            {portalItens.map((item) => (
                                <CardAccess key={item.id} {...item} />
                            ))}
                        </CustomCarousel>
                    ) : (
                        <>
                            {portalItens.map((item) => (
                                <CardAccess key={item.id} {...item} />
                            ))}
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
