import { Grid } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { formSchema } from './form-schema';

import CustomInputForm from '../../components/CustomInputForm/CustomInputForm';
import CustomButton from '../../components/CustomButton/CustomButton';
import { yupResolver } from '@hookform/resolvers';
import { ESQUECI_SENHA_LABELS } from '../../consts/text/esqueci-senha';
import {
    FORM_LOGIN_FIELDS,
    FORM_LOGIN_LABELS,
} from '../../consts/text/form-login';
import { AutenticationActions } from '../../store/ducks/autentication';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import * as session from '../../utils/session';

const FormEsqueciMinhaSenha = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(formSchema),
    });
    const onSubmit = (data) => {
        session.setUserName(data.email);
        dispatch(
            AutenticationActions.postEsqueciSenhaRequest({
                ...data,
            }),
        );
    };

    return (
        <Grid container>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12}>
                    <CustomInputForm
                        inputRef={register}
                        customClass={classes.input}
                        errors={errors}
                        label={FORM_LOGIN_LABELS.LABEL_EMAIL}
                        name={FORM_LOGIN_FIELDS.FIELD_EMAIL}
                        data-gtm-form="form"
                        data-gtm-name="email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomButton
                        className={classes.button}
                        content={ESQUECI_SENHA_LABELS.BTN}
                        variant="contained"
                        type="submit"
                        gtm={{
                            id: 'enviar-esqueci-senha-001',
                            'data-gtm-name': 'enviar-esqueci-senha',
                        }}
                    />
                </Grid>
            </form>
        </Grid>
    );
};

export default FormEsqueciMinhaSenha;
