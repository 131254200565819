import appStoreIcon from './appStoreIcon.svg';
import arrowDownCollapse from './arrowDownCollapse.svg';
import logobbseguroBranco from './bbsegurosBranco.svg';
import chat from './chat.svg';
import phoneIcon from './phoneIcon.svg';
import whatsappFooter from './whatsappFooter.svg';
import playStoreIcon from './playStoreIcon.svg';
import logoBBSeguros from './bbseguros.svg';
import { ReactComponent as TiktokIcon } from './TiktokIcon.svg';
import { ReactComponent as IconErro } from './iconErro.svg';
import logoBBSegurosAmarelo from './bbSegurosAmarelo.png';
import { ReactComponent as LogoBBSegurosHeader } from './logoBBSegurosHeader.svg';
import { ReactComponent as FaleComAGenteIcon } from './FaleComAGenteIcon.svg';

const images = {
    appStoreIcon,
    arrowDownCollapse,
    logobbseguroBranco,
    chat,
    phoneIcon,
    whatsappFooter,
    playStoreIcon,
    IconErro,
    logoBBSeguros,
    logoBBSegurosAmarelo,
    FaleComAGenteIcon,
    TiktokIcon,
    LogoBBSegurosHeader,
};

export {
    arrowDownCollapse,
    appStoreIcon,
    playStoreIcon,
    IconErro,
    logoBBSeguros,
    TiktokIcon,
    LogoBBSegurosHeader,
    logoBBSegurosAmarelo,
    FaleComAGenteIcon,
};

export default images;
