import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    backdrop: ({ zIndex }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: zIndex || theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: theme.appColors.white,
    }),
}));
