import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EsqueciMinhaSenhaIcon } from '../../assets/icons/iconEsqueciMinhaSenha.svg';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomModal from '../../components/CustomModal/CustomModal';
import ROUTES_NAME from '../../consts/routes-name';
import { ESQUECI_SENHA_LABELS } from '../../consts/text/esqueci-senha';
import FormEsqueciMinhaSenha from '../../containers/FormEsqueciMinhaSenha';
import { useStyles } from './styles';
import ReactGTM from '../../utils/gtm';

export default function EsqueciMinhaSenhaPage() {
    const classes = useStyles();
    const history = useHistory();

    const { autenticacao } = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const [modalState, setModalState] = useState({
        open: false,
        body: undefined,
        title: undefined,
        actions: undefined,
        handleClose: undefined,
        description: undefined,
        subTitle: undefined,
    });

    const handleCloseModal = () => {
        //* * Google Analytics */
        ReactGTM.event({
            event: 'modal',
            action: 'close',
            name: 'modal-esqueci-senha',
        });

        setModalState({
            open: false,
            body: undefined,
            title: undefined,
            actions: undefined,
            handleClose: undefined,
            description: undefined,
            subTitle: undefined,
        });
    };

    const handleOpenModalConfirmacao = useCallback(
        (emailAddr) => {
            //* * Google Analytics */
            ReactGTM.event({
                event: 'modal',
                action: 'open',
                name: 'modal-esqueci-senha',
            });

            setModalState({
                open: true,
                title: (
                    <div className={classes.titleModal}>
                        {ESQUECI_SENHA_LABELS.TITLE}
                    </div>
                ),
                subTitle: ESQUECI_SENHA_LABELS.EMAIL_ENVIADO(emailAddr),
                handleClose: () => {
                    handleCloseModal();
                    history.push(ROUTES_NAME.REDEFINIR_SENHA);
                },
                body: (
                    <Grid container alignItems="center" direction="column">
                        <CustomButton
                            content="Fechar"
                            onClickCallBack={() => {
                                handleCloseModal();
                                history.push(ROUTES_NAME.REDEFINIR_SENHA);
                            }}
                            className={classes.modalConfirmacao}
                        />
                    </Grid>
                ),
            });
        },
        [history, classes.titleModal, classes.modalConfirmacao],
    );

    useEffect(() => {
        if (autenticacao.esqueciSenha) {
            const { emailAddr } = autenticacao.esqueciSenha;
            handleOpenModalConfirmacao(emailAddr);
        }
    }, [autenticacao, handleOpenModalConfirmacao]);

    return (
        <>
            <Grid
                container
                className={classes.root}
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                    sm={7}
                    md={5}
                    xl={3}
                    elevation={0}
                    className={classes.containerGrid}
                >
                    <EsqueciMinhaSenhaIcon
                        className={classes.imagemEsqueciMinhaSenha}
                    />

                    <Typography variant="h1" className={classes.titulo}>
                        Esqueci minha senha
                    </Typography>

                    <Typography variant="h2" className={classes.subTitulo}>
                        Enviaremos sua senha para o e-mail cadastrado.
                    </Typography>

                    <FormEsqueciMinhaSenha />
                </Grid>
            </Grid>

            <CustomModal {...modalState} />
        </>
    );
}
