const ROUTES_NAME = {
    ATENDIMENTO: '/atendimento',
    REDEFINIR_SENHA: '/redefinir-senha',
    ESQUECI_SENHA: '/esqueci-minha-senha',
    CADASTRO: '/cadastro-usuario',
    VALIDACAO: '/validacao',
    SELECAO_ENVIO_CODIGO_PRIMEIRO_ACESSO:
        '/selecao-envio-codigo-primeiro-acesso',
    LOGIN: '/login',
    PRIMEIRO_ACESSO: '/primeiro-acesso',
    CONFIRMAR_SENHA_PRIMEIRO_ACESSO: '/confirmar-senha-primeiro-acesso',
    VALIDACAO_CODIGO_PRIMEIRO_ACESSO: '/validacao-codigo-primeiro-acesso',
    HOME: '/',
    OPIN_AUTH_CONSENT: '/authconsent',
    OPIN_LOGIN_SCREEN: '/opin',
    OPIN_AUTH_LOGIN: '/loginoauthbbopin',
};

export default ROUTES_NAME;
