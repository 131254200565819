/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers';
import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ROUTES_NAME from '../../../consts/routes-name';
import {
    FORM_LOGIN_FIELDS,
    FORM_LOGIN_LABELS,
} from '../../../consts/text/form-login';
import HOME from '../../../consts/text/home';
import { AutenticationActions } from '../../../store/ducks/autentication';
import history from '../../../utils/history';
import * as session from '../../../utils/session';
import CustomInputForm from './../../../components/CustomInputForm/CustomInputForm';
import { formSchemaLogin } from './form-schema';
import { useStyles } from './styles';

const CardLoginForm = ({ isNaoCorrentista = false, gtm }) => {
    const classes = useStyles({ isNaoCorrentista });
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const { auth } = useSelector((state) => ({
        auth: state.autenticacao,
    }));

    const {
        errors,
        register,
        handleSubmit,
        reset: resetForm,
    } = useForm({
        resolver: yupResolver(formSchemaLogin),
        defaultValues: {
            [FORM_LOGIN_FIELDS.FIELD_EMAIL]: '',
        },
    });

    const handleChangeShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const submitFormLogin = (_formData) => {
        session.setUserName(_formData[FORM_LOGIN_FIELDS.FIELD_EMAIL]);
        const data = {
            ..._formData,
            user_name: _formData[FORM_LOGIN_FIELDS.FIELD_EMAIL],
        };
        dispatch(AutenticationActions.postLoginRequest({ data }));
    };

    useEffect(() => {
        if (auth.opcaoEnvio) {
            history.push(ROUTES_NAME.VALIDACAO);
        }
    }, [auth.opcaoEnvio]);

    useEffect(() => {
        setTimeout(() => resetForm(), 100);
    }, []);

    return (
        <Grid item container alignItems="center" direction="column" spacing={2}>
            <Grid item container>
                <CustomInputForm
                    checkValid
                    inputRef={register}
                    errors={errors}
                    label={FORM_LOGIN_LABELS.LABEL_EMAIL}
                    name={FORM_LOGIN_FIELDS.FIELD_EMAIL}
                    data-gtm-form="input"
                    data-gtm-name="email"
                />
            </Grid>

            <Grid item container>
                <CustomInputForm
                    inputRef={register}
                    errors={errors}
                    type={showPassword ? 'text' : 'password'}
                    label={FORM_LOGIN_LABELS.LABEL_SENHA}
                    name={FORM_LOGIN_FIELDS.FIELD_SENHA}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleChangeShowPassword}
                                className={classes.iconeVisibility}
                            >
                                {showPassword ? (
                                    <VisibilityOutlinedIcon />
                                ) : (
                                    <VisibilityOffOutlinedIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                    data-gtm-form="input"
                    data-gtm-name="senha"
                />
            </Grid>

            <Button
                color="secondary"
                variant="contained"
                className={classes.btnAcessar}
                disableElevation
                onClick={handleSubmit(submitFormLogin)}
                {...gtm}
            >
                <Typography className={classes.btnAcessarText} {...gtm}>
                    {FORM_LOGIN_LABELS.BTN_ACESSAR}
                </Typography>
            </Button>

            <Typography
                component="a"
                className={classes.link}
                onClick={() => history.push(ROUTES_NAME.ESQUECI_SENHA)}
                data-gtm-type="click"
                data-gtm-clicktype="button"
                id="esqueci-senha-001"
                data-gtm-name="esqueci-senha"
            >
                {HOME.NAO_CORRENTISTA.ESQUECI_SENHA}
            </Typography>

            <Typography
                component="a"
                className={classes.link}
                onClick={() => history.push(ROUTES_NAME.PRIMEIRO_ACESSO)}
                data-gtm-type="click"
                data-gtm-clicktype="button"
                id="primeiro-acesso-001"
                data-gtm-name="primeiro-acesso"
            >
                {HOME.PRIMEIRO_ACESSO}
            </Typography>
        </Grid>
    );
};

export default CardLoginForm;
