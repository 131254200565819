import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
    postLoginRequest: ['payload'],
    postLoginBBOpinRequest: ['payload'],
    postPrimeiroAcessoRequest: ['payload'],
    postEnviarCodigoPrimeiroAcessoRequest: ['payload'],
    postEsqueciSenhaRequest: ['payload'],
    postAlterarSenhaRequest: ['payload', 'isPrimeiroAcesso'],
    postValidarTokenRequest: ['payload'],
    postReenviarTokenRequest: ['payload'],
    postCriarUsuarioRequest: ['payload'],
    postPerfilRequest: ['payload'],
    setPerfilResponse: ['data'],
    setCadastro: ['data'],
    setLoginResponse: ['data'],
    setPrimeiroAcessoResponse: ['data'],
    setUpdateLoading: ['loading'],
    setEsqueciSenha: ['data'],
    setConfirmaToken: ['confirmaToken'],
    setOpcaoEnvio: ['opcaoEnvio'],
    setAutenticateMessage: ['message'],
    getURLRequest: [],
    setURLResponse: ['url'],
    setDadosMascarados: ['data'],
    setIsValidadoUsuario: ['data'],
    getDadosMascaradosRequest: ['payload'],
    getConsultaExistenciaDocumentoRequest: ['payload'],
    postValidarTokenPrimeiroAcessoRequest: ['payload'],
    setErrorDescription: ['message'],
});

export const AutenticationTypes = Types;
export const AutenticationActions = Creators;

export const INITIAL_STATE = Immutable({
    message: null,
    errorDescription: null,
    esqueciSenha: null,
    confirmaToken: null,
    opcaoEnvio: null,
    loading: false,
    perfil: null,
    cadastro: null,
    url: null,
    dadosMascarados: null,
    isValidadoUsuario: false,
});

/* reducers */
const reduceLoading = (state) => state.merge({ loading: true });

const reduceUpdateLoading = (state, { loading }) => state.merge({ loading });

const autenticateMessage = (state, { message }) => {
    return state.merge({
        message,
        opcaoEnvio: null,
        confirmaToken: null,
        loading: false,
    });
};

const reduceEsqueciSenha = (state, { data }) => {
    return state.merge({ esqueciSenha: data, loading: false });
};

const reduceConfirmaToken = (state, { confirmaToken }) => {
    return state.merge({ opcaoEnvio: null, confirmaToken, loading: false });
};

const reduceOpcaoEnvio = (state, { opcaoEnvio }) => {
    return state.merge({ opcaoEnvio, confirmaToken: null, loading: false });
};

const reducePerfilResponse = (state, { data }) => {
    return state.merge({ perfil: data, loading: false });
};

const reduceCadastroResponse = (state, { data }) => {
    return state.merge({ cadastro: data });
};

const reduceURL = (state, { url }) => {
    return state.merge({ url, loading: false });
};

const reduceDadosMascarados = (state, { data }) => {
    return state.merge({ dadosMascarados: { ...data } });
};
const reduceIsValidadoUsuario = (state, { data }) => {
    return state.merge({ isValidadoUsuario: data });
};

const setErrorDescription = (state, { message }) => {
    return state.merge({
        errorDescription: message,
        loading: false,
    });
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.POST_CRIAR_USUARIO_REQUEST]: reduceLoading,
    [Types.POST_ESQUECI_SENHA_REQUEST]: reduceLoading,
    [Types.POST_ALTERAR_SENHA_REQUEST]: reduceLoading,
    [Types.POST_VALIDAR_TOKEN_REQUEST]: reduceLoading,
    [Types.POST_REENVIAR_TOKEN_REQUEST]: reduceLoading,
    [Types.POST_LOGIN_REQUEST]: reduceLoading,
    [Types.POST_LOGIN_BB_OPIN_REQUEST]: reduceLoading,
    [Types.POST_PRIMEIRO_ACESSO_REQUEST]: reduceLoading,
    [Types.POST_ENVIAR_CODIGO_PRIMEIRO_ACESSO_REQUEST]: reduceLoading,
    [Types.POST_PERFIL_REQUEST]: reduceLoading,
    [Types.SET_CADASTRO]: reduceCadastroResponse,
    [Types.SET_PERFIL_RESPONSE]: reducePerfilResponse,
    [Types.SET_UPDATE_LOADING]: reduceUpdateLoading,
    [Types.SET_ESQUECI_SENHA]: reduceEsqueciSenha,
    [Types.SET_CONFIRMA_TOKEN]: reduceConfirmaToken,
    [Types.SET_OPCAO_ENVIO]: reduceOpcaoEnvio,
    [Types.SET_AUTENTICATE_MESSAGE]: autenticateMessage,
    [Types.GET_URL_REQUEST]: reduceLoading,
    [Types.SET_URL_RESPONSE]: reduceURL,
    [Types.SET_DADOS_MASCARADOS]: reduceDadosMascarados,
    [Types.SET_IS_VALIDADO_USUARIO]: reduceIsValidadoUsuario,
    [Types.GET_DADOS_MASCARADOS_REQUEST]: reduceLoading,
    [Types.GET_CONSULTA_EXISTENCIA_DOCUMENTO_REQUEST]: reduceLoading,
    [Types.POST_VALIDAR_TOKEN_PRIMEIRO_ACESSO_REQUEST]: reduceLoading,
    [Types.SET_ERROR_DESCRIPTION]: setErrorDescription,
});
