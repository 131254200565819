import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        marginBlock: 'auto',
        border: '1px solid #CAD0F8',
        borderRadius: '4px',
        padding: '16px',
        height: '80px',
        width: '318px',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%',
        },
    },

    container: ({ isPrimeiroAcesso }) => ({
        marginTop: isPrimeiroAcesso ? 0 : theme.spacing(1),
    }),

    icon: {
        width: '42px',
        height: '35px',
        color: theme.appColors.primaryColor,
    },

    titleCard: {
        fontSize: '18px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.37px',
        color: theme.appColors.purpleText,
        border: 'none !important',
        boxShadow: 'none !important',
        '& .selected': {
            color: 'red',
        },
    },

    selected: {
        border: `1.2px solid ${theme.appColors.footerBlue}`,
        boxShadow: '0px 3px 5px 1px rgba(120, 120, 120, 0.2)',
        color: theme.appColors.primaryColor,
    },

    divData: {
        wordBreak: 'break-word',
        fontFamily: theme.typography.inter.fontFamily,
        color: theme.appColors.darkGray,
        textAlign: 'left',
        fontSize: 14,
        letterSpacing: '-0.01px',
    },

    button: ({ isPrimeiroAcesso }) => ({
        width: isPrimeiroAcesso ? 198 : 143,
        marginTop: isPrimeiroAcesso ? 60 : 47,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }),
}));
