const HOME = {
    TITLE: 'Portal do Cliente BB Seguros',
    DESCRIPTION:
        'Consulte as informações sobre suas apólices, acione seu seguro ou solicite assistências.',
    TITLE_LOGIN: 'Possui conta no Banco do Brasil?',
    NAO_CORRENTISTA: {
        TITLE: 'Não possui conta no Banco do Brasil?',
        DESCRIPTION: 'Preencha os seus dados para acessar a Área do Cliente.',
        ESQUECI_SENHA: 'Esqueci minha senha',
        SEM_CADASTRO: 'Não possui cadastro? ',
        LINK_CADASTRO: 'Clique aqui e faça seu cadastro',
    },
    PRIMEIRO_ACESSO: 'É o seu primeiro acesso? Clique Aqui',
    ASSISTENCIA: {
        SUBTITLE: 'Assistência Técnica',
        DESCRIPTION: 'Acesse com seu login e senha',
    },
};

export default HOME;
