//#region Imports
import {
    area_logada_uri,
    correntista_uri,
    gateway_uri,
    isAmbienteDevHml,
    mobile_api_uri,
    sensoriamento_remoto_uri,
} from '../config';
import { TOKEN_USUARIO_SENSORIAMENTO } from '../library/authorization';
//#endregion

/* base urls */
const API_URL = process.env.REACT_APP_APIGATEWAY_URI || 'http://localhost:8080';
const APP_AMBIENTE =
    process.env.REACT_APP_AMBIENTE?.toLowerCase() === 'prd'
        ? ''
        : `${process.env.REACT_APP_AMBIENTE?.toLowerCase()}-`;

let PORTAL_URL = 'https://security-oauth.bbseguros.com.br';

if (API_URL?.includes('hml') || API_URL?.includes('dev')) {
    const AMBIENTE = API_URL.includes('hml') ? 'hml' : 'dev';
    PORTAL_URL = `https://security.${AMBIENTE}-cld.brasilseg.com.br`;
}

const getUUID = () => {
    return localStorage.getItem('UUID');
};

const BASE_URL = `/residencial-nao-correntista`;

/* endpoints */
const ENDPOINTS = {
    TWITTER: '//www.twitter.com/bbseguros',
    FACEBOOK: '//www.facebook.com/BBSeguros',
    INSTAGRAM: '//www.instagram.com/BBSeguros/',
    YOUTUBE: '//www.youtube.com/user/bbseguros',
    LINKEDIN: '//www.linkedin.com/company/bb-seguros/',
    PINTEREST: '//br.pinterest.com/bb_seguros/',
    TIKTOK: '//www.tiktok.com/@bbseguros',
    PLAY_STORE:
        '//play.google.com/store/apps/details?id=com.devbbseg.bbsegurosdigital',
    APPLE_STORE: '//apps.apple.com/br/app/bb-seguros/id1571192763',
    WHATSAPP: 'https://api.whatsapp.com/send?phone=551140047000',
    ATENDIMENTO_24H: `httpS://www7.directtalk.com.br/clientes/custom/BBSeguros/new/index.html`,
    POLITICA_PRIVACIDADE:
        'https://www.bbseguros.com.br/politica-de-privacidade',
    URL_BBSEGUROS: '//www.bbseguros.com.br',
    SOBRE_BRASILSEG:
        'https://www.bbseguros.com.br/seguros/quem-somos/sobre-a-brasilseg',
    SUSTENTABILIDADE: 'https://www.bbseguros.com.br/seguros/sustentabilidade',
    IMPRENSA: 'https://www.bbseguros.com.br/seguros/imprensa',
    EVENTOS_PATROCINIOS: 'https://www.bbseguros.com.br/seguros/eventos',
    CLUBE_VANTAGEM: 'https://www.bbseguros.com.br/clube-de-vantagens',
    PROGRAMAS_INOVACAO: 'https://www.bbseguros.com.br/inovacao',
    RELACAO_COM_INVESTIDORES: 'https://www.bbseguridaderi.com.br/',

    //novas
    URL_PRIMEIRO_ACESSO: `${API_URL}/api/portal-cliente/acesso-cliente/criar-primeiro-acesso`,
    URL_AUTH_LOGIN: `${API_URL}/api/brseg-autenticacao/login`,
    URL_AUTH_LOGIN_BB: `${API_URL}/api/portal-cliente/acesso-cliente/login-bb`,

    TEMP_OAUTH: `${API_URL}/oauth/temp/acesso`,

    AUTH_UUID: () => `${gateway_uri}/oauth/bb?codReq=${getUUID()}`,
    GET_TOKEN: (code) =>
        `${gateway_uri}/loginoauthbb?code=${code}&state=${getUUID()}`,
    PORTAL_BB_SEGUROS: (token) =>
        `${area_logada_uri}/login-bbauth?token=${token}`,
    PORTAL_SENSORIAMENTO_REMOTO: (token) => {
        const customToken = isAmbienteDevHml
            ? TOKEN_USUARIO_SENSORIAMENTO
            : token;
        return `${sensoriamento_remoto_uri}/web?tokenbbauth=${customToken}`;
    },
    BACKEND_SENSORIAMENTO_REMOTO: `${mobile_api_uri}/sensoriamento-mobile/login/verifica-acesso-web`,
    PORTAL_CORRENTISTA: `${correntista_uri}/login?app=portal`,
    ACESSO_DADOS: {
        CONSULTA: `${gateway_uri}/api/portal-cliente/formulario-consulta-informacoes`,
        OUTRAS: `${gateway_uri}/api/portal-cliente/formulario-outras-informacoes`,
    },
    SENSORIAMENTO_LOGIN: `${sensoriamento_remoto_uri}/web/login`,
    URL_AUTH_DEFINIR_SENHA_PRIMEIRO_ACESSO: `${API_URL}/api/portal-cliente/acesso-cliente/definir-senha-acesso`,
    URL_AUTH_ENVIAR_CODIGO_PRIMEIRO_ACESSO: `${API_URL}/api/portal-cliente/acesso-cliente/enviar-acesso`,
    URL_AUTH_ALTERAR_SENHA: `${API_URL}/api/brseg-autenticacao/alterar-senha`,
    URL_AUTH_ESQUECI_SENHA: `${API_URL}/api/brseg-autenticacao/esqueci-senha`,
    URL_AUTH_VALIDAR_TOKEN: `${API_URL}/api/brseg-autenticacao/validar-codigo`,
    URL_AUTH_ENVIAR_TOKEN: `${API_URL}/api/brseg-autenticacao/envia-codigo`,
    GET_DADOS_MASCARADOS: (email) =>
        `${gateway_uri}/api/brseg-autenticacao/revalidar-usuario?email=${email}`,
    GET_DADOS_MASCARADOS_PRIMEIRO_ACESSO: (cpfCnpj, tipoPessoa) =>
        `${gateway_uri}/api/portal-cliente/acesso-cliente/listar-contato-reenvio-acesso?tipoPessoa=${tipoPessoa}&cpfCnpj=${cpfCnpj}`,
    LOGIN_BB_APP: (token) =>
        `${gateway_uri}/api/brseg-autenticacao/login-bb-arealogada?tokenbb=${token}`,
    CONSULTA_EXISTENCIA_DOCUMENTO: (cpfCnpj, data, tipoPessoa) =>
        `${gateway_uri}/api/portal-cliente/acesso-cliente/consultar-existencia-documento?tipoPessoa=${tipoPessoa}&cpfCnpj=${cpfCnpj}${
            data ? `&data=${data}` : ''
        }`,
    VALIDAR_CODIGO_ACESSO_PRIMEIRO_ACESSO: `${gateway_uri}/api/portal-cliente/acesso-cliente/validar-acesso`,

    IFRAME_FOOTER: `https://footer-nao-correntista.${APP_AMBIENTE}cld.brasilseg.com.br`,
};

export { BASE_URL, ENDPOINTS, PORTAL_URL };

export default ENDPOINTS;
