import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ENDPOINTS, PORTAL_URL } from '../../consts/endpoints';
import ROUTES_NAME from '../../consts/routes-name';
import { SYSTEM_MESSAGE } from '../../consts/text/system-msg';
import AutenticationService from '../../services/autentication';
import { formatDateBrToUsIso } from '../../utils/formatters';
import history from '../../utils/history';
import {
    onlyNums,
    onlyNumsAndXString,
} from '../../utils/normalizers/normalizers';
import * as session from '../../utils/session';
import {
    AutenticationActions,
    AutenticationTypes,
} from '../ducks/autentication';
import ReactGTM from '../../utils/gtm';

const service = new AutenticationService();

const {
    POST_LOGIN_REQUEST,
    POST_LOGIN_BB_OPIN_REQUEST,
    POST_PRIMEIRO_ACESSO_REQUEST,
    POST_ENVIAR_CODIGO_PRIMEIRO_ACESSO_REQUEST,
    POST_ESQUECI_SENHA_REQUEST,
    POST_ALTERAR_SENHA_REQUEST,
    POST_VALIDAR_TOKEN_REQUEST,
    POST_REENVIAR_TOKEN_REQUEST,
    GET_URL_REQUEST,
    GET_DADOS_MASCARADOS_REQUEST,
    GET_CONSULTA_EXISTENCIA_DOCUMENTO_REQUEST,
    POST_VALIDAR_TOKEN_PRIMEIRO_ACESSO_REQUEST,
} = AutenticationTypes;

function* loginRequest({ payload }) {
    try {
        const { data } = yield call(service.postLoginBb, payload?.data);

        const tokenEncode = encodeURIComponent(data?.resultado?.token);
        window.location.assign(ENDPOINTS.PORTAL_BB_SEGUROS(tokenEncode));

        //* * Google Analytics */
        ReactGTM.event({
            event: 'login',
            tipo_login: 'padrao-email',
            user_id: payload?.data?.email,
            retorno: 'sucesso',
            descricao:
                'Sucesso ao realizar o login para não correntistas do Banco do Brasil.',
            erro: {
                codigo: '',
                servico: '',
                mensagem: '',
            },
        });
    } catch (e) {
        const status = e?.response?.status;

        if (
            status === 403 &&
            e?.response?.data?.message === 'Usuário não validado'
        ) {
            const { data } = yield call(service.getDadosMascarados, {
                email: payload?.data.email,
            });
            yield put(AutenticationActions.setDadosMascarados(data.resultado));
            yield put(AutenticationActions.setOpcaoEnvio(true));

            //* * Google Analytics */
            ReactGTM.event({
                event: 'erro',
                servico: 'loginRequest',
                retorno: 'erro',
                erro: {
                    servico: 'loginRequest',
                    mensagem: e?.response?.data?.message,
                    codigo: e.response.status,
                },
            });
        } else {
            let msg =
                e?.response?.data?.message ||
                SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;

            yield put(AutenticationActions.setAutenticateMessage(msg));

            //* * Google Analytics */
            ReactGTM.event({
                event: 'erro',
                servico: 'loginRequest',
                retorno: 'erro',
                erro: {
                    servico: 'loginRequest',
                    mensagem: msg,
                    codigo: e.response.status,
                },
            });
        }
        return e;
    }
}

function* loginBBOpinRequest({ payload }) {
    try {
        const { data } = yield call(service.postLoginBb, payload?.data);
        localStorage.setItem('type_account', 'naoCorrentista');
        localStorage.setItem('token', data?.resultado?.token);

        window.location.assign(
            `${PORTAL_URL}/loginoauthbbopin?token=${data?.resultado?.token}`,
        );
    } catch (e) {
        const status = e?.response?.status;
        if (
            status === 403 &&
            e?.response?.data?.message === 'Usuário não validado'
        ) {
            const { data } = yield call(service.getDadosMascarados, {
                email: payload?.data.email,
            });
            yield put(AutenticationActions.setDadosMascarados(data.resultado));
            yield put(AutenticationActions.setOpcaoEnvio(true));
        } else {
            let msg =
                e?.response?.data?.message ||
                SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;

            yield put(AutenticationActions.setAutenticateMessage(msg));
        }
        return e;
    }
}

function* primeiroAcessoRequest({ payload }) {
    try {
        const { data } = yield call(service.postPrimeiroAcesso, {
            cpfCnpj: onlyNums(payload?.cpfCnpj),
            data: payload?.data
                ? formatDateBrToUsIso(payload?.data)
                : payload?.data,
            senha: payload?.senha,
            tipoPessoa: payload?.tipoPessoa,
        });
        yield put(
            AutenticationActions.setDadosMascarados({
                ...data?.resultado,
            }),
        );
        yield put(AutenticationActions.setOpcaoEnvio(true));
        //* * Google Analytics */
        ReactGTM.event({
            event: 'primeiro-acesso',
            servico: 'primeiroacessoRequest',
            retorno: 'sucesso',
            descricao: 'Sucesso no serviço no de primeito acesso.',
            erro: {
                codigo: '',
                servico: '',
                mensagem: '',
            },
        });
        history.push(ROUTES_NAME.SELECAO_ENVIO_CODIGO_PRIMEIRO_ACESSO);
    } catch (e) {
        let msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        //* * Google Analytics */
        ReactGTM.event({
            event: 'erro',
            servico: 'primeiroacessoRequest',
            retorno: 'erro',
            erro: {
                servico: '',
                mensagem: msg,
                codigo: e?.response?.status,
            },
        });
        return e;
    }
}

function* esqueciSenhaRequest({ payload }) {
    try {
        const { data } = yield call(service.postEsqueciSenha, payload);

        yield put(AutenticationActions.setEsqueciSenha(data.resultado));

        //* * Google Analytics */
        ReactGTM.event({
            event: 'esqueci-senha',
            tipo_login: 'padrao-email',
            user_id: payload?.email,
            retorno: 'sucesso',
            descricao: 'Sucesso ao relatar o esquecimento de senha.',
            erro: {
                codigo: '',
                servico: '',
                mensagem: '',
            },
        });
    } catch (e) {
        let msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;

        yield put(AutenticationActions.setAutenticateMessage(msg));

        //* * Google Analytics */
        ReactGTM.event({
            event: 'erro',
            servico: 'esqueciSenhaRequest',
            retorno: 'erro',
            erro: {
                servico: 'esqueciSenhaRequest',
                mensagem: msg,
                codigo: e?.response?.status,
            },
        });

        return e;
    }
}

function* alterarSenhaRequest({ payload, isPrimeiroAcesso }) {
    try {
        if (isPrimeiroAcesso) {
            yield call(service.putDefinirSenha, payload);
            yield put(AutenticationActions.setIsValidadoUsuario(true));
            history.push(ROUTES_NAME.PRIMEIRO_ACESSO);
        } else {
            yield call(service.postAlterarSenha, payload);
            history.push(ROUTES_NAME.LOGIN);
        }
        yield put(AutenticationActions.setAutenticateMessage(''));
        yield put(AutenticationActions.setUpdateLoading(false));
    } catch (e) {
        const msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        return e;
    }
}

function* validarTokenRequest({ payload }) {
    try {
        yield call(service.postValidarToken, payload);

        yield put(AutenticationActions.setOpcaoEnvio(false));

        let cadastro = yield select((state) => state.autenticacao.cadastro);
        yield put(AutenticationActions.setCadastro(cadastro + ' e validado'));
        yield put(AutenticationActions.setUpdateLoading(false));
        history.push(ROUTES_NAME.LOGIN);
    } catch (e) {
        const msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        return e;
    }
}

function* reenviarTokenRequest({ payload }) {
    try {
        const { status } = yield call(service.postEnviarToken, payload);

        if (status === 200) {
            const tipo = payload?.sms ? 'SMS' : 'EMAIL';
            yield put(AutenticationActions.setConfirmaToken(tipo));

            if (payload?.esqueciSenha) {
                history.push(ROUTES_NAME.REDEFINIR_SENHA, {
                    esqueciSenha: true,
                    dadoMascarado: payload?.dadoMascarado,
                });
            }
        }
    } catch (e) {
        const msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        return e;
    }
}

function* enviarCodigoPrimeiroAcesso({ payload }) {
    try {
        const { status } = yield call(
            service.postEnviarCodigoPrimeiroAcesso,
            payload,
        );

        if (status === 200) {
            history.push(ROUTES_NAME.VALIDACAO_CODIGO_PRIMEIRO_ACESSO, {
                tipoEnvio: payload?.tipoEnvio,
            });
        }
    } catch (e) {
        const msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        return e;
    }
}
function* getURLRequest() {
    try {
        const { data } = yield call(service.getURL);
        yield put(AutenticationActions.setURLResponse(data.url));
        yield put(AutenticationActions.setOpcaoEnvio(null));
    } catch (e) {
        const msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        return e;
    }
}

function* getDadosMascarados({ payload }) {
    try {
        let data = null;
        if (payload?.isPrimeiroAcesso) {
            data = yield call(service.getDadosMascaradosPrimeiroAcesso, {
                cpfCnpj: payload?.cpfCnpj,
                tipoPessoa: payload?.tipoPessoa,
            });
            yield put(
                AutenticationActions.setDadosMascarados({
                    ...data?.data?.resultado,
                }),
            );
        } else {
            data = yield call(service.getDadosMascarados, {
                email: payload?.email,
            });
            yield put(
                AutenticationActions.setDadosMascarados({
                    ...data?.data?.resultado,
                    emailAddr: data?.data?.resultado?.emailAddr,
                }),
            );
        }
        yield put(AutenticationActions.setOpcaoEnvio(true));
    } catch (e) {
        const msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        return e;
    }
}

function* getConsultaExistenciaDocumento({ payload }) {
    try {
        const { status } = yield call(service.consultaExistenciaDocumentos, {
            cpfCnpj: onlyNums(payload?.nmCpfCnpj),
            data: payload?.data ? formatDateBrToUsIso(payload?.data) : null,
            tipoPessoa: payload?.tipoPessoa,
        });

        ReactGTM.event({
            event: 'consultarExisteciaDocumento',
            user_id: payload?.nmCpfCnpj,
            retorno: 'sucesso',
            descricao: 'Sucesso ao consultar documento.',
            erro: {
                codigo: '',
                servico: '',
                mensagem: '',
            },
        });

        if (status === 200) {
            history.push(ROUTES_NAME.CONFIRMAR_SENHA_PRIMEIRO_ACESSO, {
                isPrimeiroAcesso: true,
            });
            session.setUserNamePrimeiroAcesso(
                onlyNumsAndXString(payload?.nmCpfCnpj),
            );
            session.setDataNascPrimeiroAcesso(
                formatDateBrToUsIso(payload?.data),
            );
        }
    } catch (e) {
        let msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        let msgDescription = e?.response?.data?.erros?.[0]?.erro;

        yield put(AutenticationActions.setAutenticateMessage(msg));
        yield put(AutenticationActions.setErrorDescription(msgDescription));

        //* * Google Analytics */
        ReactGTM.event({
            event: 'erro',
            servico: 'consultarExisteciaDocumento',
            retorno: 'erro',
            erro: {
                servico: 'consultarExisteciaDocumento',
                mensagem: msg,
                codigo: e?.response?.status,
            },
        });
        return e;
    }
}

function* validarTokenPrimeiroAcessoRequest({ payload }) {
    try {
        yield call(service.postValidarTokenPrimeiroAcesso, payload);

        yield put(AutenticationActions.setOpcaoEnvio(false));

        let cadastro = yield select((state) => state.autenticacao.cadastro);
        yield put(AutenticationActions.setCadastro(cadastro + ' e validado'));
        yield put(AutenticationActions.setUpdateLoading(false));
        history.push(ROUTES_NAME.PRIMEIRO_ACESSO, { isValidated: true });
    } catch (e) {
        const msg =
            e?.response?.data?.message ?? SYSTEM_MESSAGE.ERRO_AO_SE_COMUNICAR;
        yield put(AutenticationActions.setAutenticateMessage(msg));
        return e;
    }
}

export default function* watcher() {
    yield all([
        takeLatest(POST_LOGIN_REQUEST, loginRequest),
        takeLatest(POST_LOGIN_BB_OPIN_REQUEST, loginBBOpinRequest),
        takeLatest(POST_PRIMEIRO_ACESSO_REQUEST, primeiroAcessoRequest),
        takeLatest(
            POST_ENVIAR_CODIGO_PRIMEIRO_ACESSO_REQUEST,
            enviarCodigoPrimeiroAcesso,
        ),
        takeLatest(POST_ESQUECI_SENHA_REQUEST, esqueciSenhaRequest),
        takeLatest(POST_ALTERAR_SENHA_REQUEST, alterarSenhaRequest),
        takeLatest(POST_VALIDAR_TOKEN_REQUEST, validarTokenRequest),
        takeLatest(POST_REENVIAR_TOKEN_REQUEST, reenviarTokenRequest),
        takeLatest(GET_URL_REQUEST, getURLRequest),
        takeLatest(GET_DADOS_MASCARADOS_REQUEST, getDadosMascarados),
        takeLatest(
            GET_CONSULTA_EXISTENCIA_DOCUMENTO_REQUEST,
            getConsultaExistenciaDocumento,
        ),
        takeLatest(
            POST_VALIDAR_TOKEN_PRIMEIRO_ACESSO_REQUEST,
            validarTokenPrimeiroAcessoRequest,
        ),
    ]);
}
