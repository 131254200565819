//#region Imports

import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect, useCallback } from 'react';
import TabPanel from '../../containers/TabPanel';
import LGPD from '../../consts/text/lgpd';
import useStyles from './styles';
import AtualizacaoDados from './AtualizacaoDados';
import FormConsultaDados from './forms/FormConsultaDados';
import { getParameterByName } from '../../library/get-data';
import FormOutrasSolicitacoes from './forms/FormOutrasSolicitacoes';
import { appColors } from '../../AppTheme';

//#endregion

const { TITLE, PARAGRAPH, TABS } = LGPD;

const Lgpd = ({ history }) => {
    const styles = useStyles();
    const [urlToken, setUrlToken] = useState(null);
    const [tabValue, setTabValue] = useState(0);

    const handleToken = useCallback(() => {
        const token = getParameterByName('token');

        if (token) {
            setUrlToken(encodeURIComponent(token));
        } else {
            history.push('/home');
        }
    }, [history]);

    useEffect(() => {
        handleToken();
    }, [handleToken]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            {urlToken && (
                <>
                    <Container
                        maxWidth="md"
                        style={{ minHeight: 'calc(100vh - 156px)' }}
                    >
                        <Box
                            bgcolor={appColors.alternativeGray}
                            color={appColors.gray}
                            padding="1em 2%"
                        >
                            <Typography variant="h4">{TITLE}</Typography>
                            <br />
                            <Typography variant="subtitle2">
                                {PARAGRAPH}
                            </Typography>
                        </Box>
                        <AppBar
                            position="static"
                            color="default"
                            className={styles.appBar}
                        >
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                selectionFollowsFocus={true}
                            >
                                {Object.values(TABS).map((label, index) => (
                                    <Tab
                                        key={index}
                                        {...{ label }}
                                        className={styles.tab}
                                    />
                                ))}
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <FormConsultaDados token={urlToken} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <AtualizacaoDados />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <FormOutrasSolicitacoes token={urlToken} />
                        </TabPanel>
                    </Container>
                </>
            )}
        </>
    );
};

export default withRouter(Lgpd);
