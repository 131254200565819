import TagManager from 'react-gtm-module';
import ROUTES_NAME from '../consts/routes-name';
import { criptoToAES } from '../utils/criptografia';

const getSteps = (location) => {
    let step_number = '';
    let step_description = '';
    let step_basic = '';
    let step_common = '';

    switch (location) {
        case ROUTES_NAME.LOGIN:
            step_number = 'step1';
            step_common = 'step1';
            step_description = 'area-cliente-login';
            step_basic = 'p1:quoting start';
            break;
        case ROUTES_NAME.ESQUECI_SENHA:
            step_number = 'step2';
            step_common = 'step2';
            step_description = 'area-cliente-esqueci-senha';
            break;
        case ROUTES_NAME.PRIMEIRO_ACESSO:
            step_number = 'step3';
            step_common = 'step3';
            step_description = 'area-cliente-cadastro';
            break;
        default:
            break;
    }

    return {
        step_number,
        step_description,
        step_basic,
        step_common,
    };
};

const getProccessId = () => {
    const KEY = 'progress_id';

    const count = window.localStorage.getItem(KEY);

    let newCount = count ? Number(count) + 1 : 1;

    window.localStorage.setItem(KEY, newCount);

    return newCount;
};

export default {
    init(nrCotacao) {
        if (nrCotacao) {
            TagManager.initialize({
                gtmId: 'GTM-TW684H6',
                userId: nrCotacao,
            });
        }
    },

    pageView(location, eventInfo) {
        if (!location) return;

        /* Global Variable */
        window.digitalData = {
            error_type: '',
            error_message: '',
            process_id: 'tc residencial',
            registration_operation_id: '',
            step_version: '',
            flow_id: '1.0',
            url: window.location.href,
            full_operation_id: new Date().getTime() + 'NC',
            progress_id: getProccessId(),
            ...getSteps(location),
        };

        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                page: { url: location },
                ...eventInfo,
            },
        });
    },

    customData(page_name, user_cpf = undefined, logged = false, data) {
        const networkInformation = navigator.connection;
        const isMobile = window.screen.width <= 768;
        const encriptedCpf = criptoToAES(user_cpf);

        //eslint-disable-next-line
        window.customData = {
            ...data,
            page: {
                name: page_name,
            },
            site: {
                brand: 'bb-seguros',
                version: '1.0',
                channel: isMobile ? 'mobile' : 'web',
            },
            user: {
                // numero_cotacao: encriptedCotacao,
                connection: networkInformation?.effectiveType,
                user_id: encriptedCpf,
                logged,
            },
        };
    },

    event(eventInfo) {
        if (!eventInfo) return;

        TagManager.dataLayer({
            dataLayer: {
                ...eventInfo,
            },
        });
    },
};
