import { isValid, parseISO } from 'date-fns';
import { normalizers } from '..';

export const validateNomeSegurado = (value) => {
    return /^([A-ZÀ-Ú][a-zà-ú]{2,50})((\s[d][a|e|i|o|u])?\s[A-ZÀ-Ú][a-zà-ú]{2,50})+$/.test(
        value,
    );
};

export const validateCompare = (valueToCompare) => (value) => {
    if (!value || !valueToCompare) return false;
    return valueToCompare === value;
};

export const validateCPF = (strCPF) => {
    strCPF = strCPF.replace(/[^\d]+/g, '');
    let Soma;
    let Resto;
    Soma = 0;
    if (
        strCPF === '00000000000' ||
        strCPF === '11111111111' ||
        strCPF === '22222222222' ||
        strCPF === '33333333333' ||
        strCPF === '44444444444' ||
        strCPF === '55555555555' ||
        strCPF === '66666666666' ||
        strCPF === '77777777777' ||
        strCPF === '88888888888' ||
        strCPF === '99999999999'
    )
        return false;
    for (let i = 1; i <= 9; i++)
        Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
    Soma = 0;
    for (let i = 1; i <= 10; i++)
        Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
};

export function validateCNPJ(cnpj) {
    if (cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj.length !== 14) return false;
        if (/^(\d)\1+$/.test(cnpj)) return false;

        const t = cnpj.length - 2;
        const d = cnpj.substring(t);
        const d1 = parseInt(d.charAt(0));
        const d2 = parseInt(d.charAt(1));
        const calc = (x) => {
            const n = cnpj.substring(0, x);
            let y = x - 7;
            let s = 0;
            let r = 0;

            for (let i = x; i >= 1; i--) {
                s += n.charAt(x - i) * y--;
                if (y < 2) y = 9;
            }

            r = 11 - (s % 11);
            return r > 9 ? 0 : r;
        };

        return calc(t) === d1 && calc(t + 1) === d2;
    }
    return cnpj;
}

export const validateCPFAndCNPJ = (strCpfCnpj) => {
    const cpfCnpj = normalizers.onlyNums(strCpfCnpj);
    if (cpfCnpj.length <= 11) {
        return validateCPF(cpfCnpj);
    }
    return validateCNPJ(cpfCnpj);
};

export async function validateCEP(cep) {
    const filteredCEP = normalizers.onlyNums(cep);

    if (filteredCEP && filteredCEP.length < 8) {
        return false;
    }
    return true;
}

export async function validatePhone(phone) {
    const filteredPhone = normalizers.onlyNums(phone);

    if (filteredPhone && filteredPhone.length < 10) {
        return false;
    }
    return true;
}

export async function validateCellPhone(phone) {
    const filteredPhone = normalizers.onlyNums(phone);
    let onlySameNumber = true;

    // Nao permite numeros iguais ex: 111111111
    for (let i = 3; i < filteredPhone.length; i++) {
        if (filteredPhone.charAt(i) !== filteredPhone.charAt(i - 1)) {
            onlySameNumber = false;
        }
    }

    if ((filteredPhone && filteredPhone.length < 11) || onlySameNumber) {
        return false;
    }
    return true;
}

export async function validateDate(date) {
    if (date) {
        const parsedDate = parseISO(date.split('/').reverse().join('-'));
        const validDate = isValid(parsedDate);

        if (validDate === false) {
            window.scrollTo({
                top: 100,
                behavior: 'smooth',
            });
        }

        const dtBir = new Date(parsedDate);
        const dtNow = new Date();

        const a = dtNow.getFullYear();
        const b = dtBir.getFullYear();

        const isDiff = a - b >= 18 && a - b <= 100;

        if (!isDiff) {
            window.scrollTo({
                top: 100,
                behavior: 'smooth',
            });
        }

        return isDiff;
    }
    window.scrollTo({
        top: 100,
        behavior: 'smooth',
    });
    return false;
}

export async function validateCompanyDate(date) {
    if (date) {
        const parsedDate = parseISO(date.split('/').reverse().join('-'));
        const validDate = isValid(parsedDate);

        if (validDate === false) {
            window.scrollTo({
                top: 100,
                behavior: 'smooth',
            });
        }

        const dtBir = new Date(parsedDate);
        const dtNow = new Date();

        const a = dtNow.getFullYear();
        const b = dtBir.getFullYear();

        const isDiff = a - b >= 0 && a - b <= 1000;

        if (!isDiff) {
            window.scrollTo({
                top: 100,
                behavior: 'smooth',
            });
        }

        return isDiff;
    }
    window.scrollTo({
        top: 100,
        behavior: 'smooth',
    });
    return false;
}
