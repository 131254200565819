//#region Imports

import { createStyles, makeStyles } from "@material-ui/core/styles";

//#endregion

export const useStyles = () => {
    const styles = makeStyles(() =>
        createStyles({
            field: {
                minWidth: "40%",
            },
            fieldBox: {
                minWidth: "60%",
            },
            tooltipClass: {
                fontSize: 13,
            },
        })
    );

    return styles();
};

export default useStyles;
