import React from 'react';

export const SYSTEM_MESSAGE = {
    ERRO_AO_SE_COMUNICAR: 'Erro ao se comunicar com o servidor.',
    OPERACAO_INVALIDA: 'Não foi possivel realizar essa operação',
    COMPRA_NAO_AUTORIZADA: 'Compra não autorizada',
    CREDENCIAIS_INVALIDAS: 'Credenciais Inválidas',
    ERRO_PARCEIRO_NAO_AUTORIZADO: (
        <p>
            Este endereço só funciona quando você chega aqui direcionado pela
            página da campanha BB Seguros,
            <br />
            <br />
            Por favor, retorne à página original.
        </p>
    ),
    ERRO: 'Erro',
};
