import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        padding: '40px 0',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        backgroundColor: theme.appColors.backGroundColorPrimary,
    },
    backBtn: {
        color: '#4F66FF',
        fontFamily: 'BancoDoBrasilTitulos',
        fontSize: '14px',
        letterSpacing: '-0.12px',
        lineHeight: '32px',
        cursor: 'pointer',
        width: 'fit-content',
    },
    formContainer: {
        maxWidth: '430px',
        minWidth: '320px',
        backgroundColor: '#FFFFFF',
        border: '1px solid #DDE4FC',
        borderRadius: '8px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '0px',
        },
    },
}));
