//#region Imports

import axios from 'axios';
import { auth_portal_token } from '../config';
import * as session from '../utils/session';
import ENDPOINTS from '../consts/endpoints';

//#endregion

const api = axios.create({
    timeout: 60000,
});

async function createSessao() {
    try {
        const host = document.location.host;
        const { data } = await axios.get(ENDPOINTS.TEMP_OAUTH, {
            headers: { origin: host, referer: host },
        });

        session.setSession(data.nmIdSessao);
    } catch (error) {
        console.error(error);
    }
}

async function configHandler(config) {
    const token = config?.CustomAuthorization || auth_portal_token;

    if (!config?.noAuthorization && token) {
        config.headers.Authorization = token;
    }

    if (!session.isSession()) {
        await createSessao();
    }
    const nmIdSessao = session.getSession();

    config.headers = {
        ...config.headers,
        nmIdSessao,
    };
    config.crossdomain = true;
    return config;
}

async function errorHandler(e) {
    const status = e?.response?.status;

    if (status === 403) {
        await createSessao();
    }

    return Promise.reject(e);
}

api.interceptors.request.use(
    (config) => configHandler(config),
    (error) => errorHandler(error),
);

api.interceptors.response.use(
    (response) => response,
    (error) => errorHandler(error),
);

export default api;
