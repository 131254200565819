import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    outlined: {
        minWidth: 134,
        height: 48,
        border: `solid 1px ${theme.palette.primary.main}`,
        fontWeight: 'bold',
        textTransform: 'none',
        marginTop: theme.spacing(2),
    },
    contained: {
        minWidth: 134,
        height: 48,
        textTransform: 'none',
        backgroundColor: (props) => (props.error ? '#CD0000' : null),
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
        boxShadow: 'none !important',
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.light,
            color: theme.appColors.white,
            opacity: 0.4,
        },
        '&:hover': {
            backgroundColor: (props) => (props.error ? '#AF0000' : null),
        },
        '& p': {
            color: '#fff',
            fontFamily: 'BancoDoBrasilTitulos',
            fontSize: 14,
            lineHeight: '20px',
            letterSpacing: '0.66px',
            textAlign: 'center',
        },
    },
    content: {
        width: '100%',
    },
}));
