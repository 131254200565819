import ROUTES_NAME from '../consts/routes-name';

const ROUTES = [
    {
        path: '/',
        exact: true,
        component: require('../pages/home').default,
    },
    {
        path: '/home',
        exact: true,
        component: require('../pages/home').default,
    },
    {
        path: ROUTES_NAME.LOGIN,
        exact: true,
        component: require('../pages/login').default,
    },
    {
        path: '/loginoauthbb',
        exact: true,
        component: require('../pages/loginoauthbb').default,
    },
    {
        path: '/loginbboauthapp',
        exact: true,
        component: require('../pages/loginbboauthapp').default,
    },
    {
        path: '/formulario-solicitacao',
        exact: true,
        component: require('../pages/lgpd').default,
    },
    {
        path: ROUTES_NAME.REDEFINIR_SENHA,
        exact: true,
        component: require('../pages/redefinir').default,
    },
    {
        exact: true,
        path: ROUTES_NAME.ESQUECI_SENHA,
        component: require('../pages/esqueciMinhaSenha').default,
    },
    {
        path: ROUTES_NAME.VALIDACAO,
        exact: true,
        component: require('../pages/confirmacaoSeguranca').default,
    },
    {
        path: ROUTES_NAME.SELECAO_ENVIO_CODIGO_PRIMEIRO_ACESSO,
        exact: true,
        component: require('../pages/selecaoEnvioCodigoPrimeiroAcesso').default,
    },
    {
        path: ROUTES_NAME.PRIMEIRO_ACESSO,
        exact: true,
        component: require('../pages/primeiroAcesso').default,
    },
    {
        path: ROUTES_NAME.CONFIRMAR_SENHA_PRIMEIRO_ACESSO,
        exact: true,
        component: require('../pages/confirmarSenhaPrimeiroAcesso').default,
    },
    {
        path: ROUTES_NAME.VALIDACAO_CODIGO_PRIMEIRO_ACESSO,
        exact: true,
        component: require('../pages/validacaoCodigoPrimeiroAcesso').default,
    },
    {
        path: ROUTES_NAME.OPIN_AUTH_CONSENT,
        exact: true,
        component: require('../pages/opin/authconsent').default,
    },
    {
        path: ROUTES_NAME.OPIN_LOGIN_SCREEN,
        exact: true,
        component: require('../pages/opin/home').default,
    },
    {
        path: ROUTES_NAME.OPIN_AUTH_LOGIN,
        exact: true,
        component: require('../pages/opin/loginoauthbbopin').default,
    },
    {
        path: '*',
        component: require('../pages/home').default,
    },
];

export default ROUTES;
