export const PROPONENTE_FIELDS = {
    OBJETO_PROPONENTE: 'proponente',
    CPF_OU_CNPJ: 'nmCpfCnpj',
    NOME_PESSOA: 'nmNome',
    OBJETO_PESSOA_FISICA: 'pessoaFisica',
    DATA_NASCIMENTO: 'dtNascimento',
    DATA: 'data',
    TIPO_PROPONENTE: 'tpProponente',
    NUMERO_TIPO_PROPONENTE: 'nrProponenteTipo',
    LISTA_DOCUMENTOS: 'lsDocumento',
    NUMERO_DOCUMENTO: 'nmNumeroDocumento',
    LISTA_EMAILS: 'lsEmail',
    NOME_EMAIL: 'nmEmail',
    LISTA_TELEFONES: 'lsTelefone',
    NUMERO_TELEFONE: 'nmNumero',
    LISTA_ENDERECOS: 'lsEndereco',
    TIPO_ENDERECO: 'tpEndereco',
    NOME_BAIRRO: 'nmBairro',
    NUMERO_CEP: 'nmCep',
    NOME_COMPLEMENTO: 'nmComplemento',
    NOME_LOGRADOURO: 'nmLogradouro',
    NOME_MUNICIPIO: 'nmMunicipio',
    NUMERO_ENDERECO: 'nmNumero',
    NOME_PAIS: 'nmPais',
    NOME_UF: 'nmUf',
    ID_PROPONENTE: 'nmIdentificador',
    NOME_PESSOA_CONTEUDO: 'nmConteudo',
    CPF_OU_CNPJ_CONTEUDO: 'nmCpfCnpjConteudo',
    IN_PRINCIPAL: 'inPrincipal',
    TIPO_TELEFONE: 'tpTelefone',
    TIPO_EMAIL: 'tpEmail',
    TIPO_DOCUMENTO: 'tpDocumento',
    DDD_TELEFONE: 'nmDdd',
    ACEITA_COMUNICACAO: 'inAceitaComunicacao',
    SOBRENOME: 'nmSobreNome',
    NOME_PESSOA_RAZAO_SOCIAL: 'nmRazaoSocial',
};
