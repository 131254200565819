import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './styles';

/**
 *
 * @CustomButton Componente generico para exibir um simples Botão
 * @CriadoEm 05/05/2020
 */
export default function CustomButton({
    content,
    onClickCallBack,
    variant,
    className,
    color,
    error,
    gtm,
    ...props
}) {
    const classes = useStyles({ error });

    return (
        <Button
            {...props}
            variant={variant}
            className={clsx(classes[variant], className)}
            onClick={onClickCallBack}
            color={color || 'primary'}
        >
            <Typography
                {...gtm}
                className={classes.content}
                data-gtm-type="click"
                data-gtm-clicktype="button"
            >
                {content}
            </Typography>
        </Button>
    );
}

/**
 * @prop content {string} Nome que será atribuido ao botão
 * @prop onClickCallBack {func} função callback que será executada no onClick do botão
 * @prop variant {string} propriedade de variant do botão
 * @prop className {string} classe que será atribuida ao botão
 */
CustomButton.propTypes = {
    content: PropTypes.string.isRequired,
    onClickCallBack: PropTypes.func,
    variant: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
};

CustomButton.defaultProps = {
    variant: 'contained',
    className: '',
    error: false,
    onClickCallBack: () => {},
};
