export const ambiente = process.env.REACT_APP_AMBIENTE;

export const isAmbienteDevHml = Boolean(
    ambiente?.toLocaleLowerCase() === 'dev' ||
        ambiente?.toLocaleLowerCase() === 'hml',
);

export const gateway_uri =
    process.env.REACT_APP_APIGATEWAY_URI ||
    'https://apigateway.dev-cld.brasilseg.com.br';

export const mobile_api_uri =
    process.env.REACT_APP_MOBILE_API_SENSORIAMENTO ||
    'https://mobile-api.hml-cld.brasilseg.com.br/api';

export const area_logada_uri =
    process.env.REACT_APP_ANGULAR_URI ||
    'https://area-logada.cld.brasilseg.com.br';

export const correntista_uri =
    process.env.REACT_APP_CORRENTISTA_URI ||
    'https://residencial.cld.brasilseg.com.br';

export const sensoriamento_remoto_uri = isAmbienteDevHml
    ? 'https://sensoriamento-bb.hml-cld.brasilseg.com.br'
    : process.env.REACT_APP_SENSORIAMENTO_REMOTO_URI ||
      'https://sensoriamento-bb.dev-cld.brasilseg.com.br';

export const auth_portal_token = process.env.REACT_APP_PORTAL_TOKEN;
