import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    titleModal: {
        fontSize: 24,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
    },
    desciptionModal: {
        fontSize: 20,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 0.15,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 260,
        },
    },

    iconeVisibility: {
        color: theme.appColors.bgBlue,
        padding: 0,
    },
    cadastroTxt: {
        fontFamily: 'Inter',
        fontSize: 14,
        letterSpacing: -0.58,
    },

    errorMessage: {
        color: theme.appColors.errorRed,
        fontFamily: 'Inter',
        margin: 10,
    },
    btnAcessar: {
        width: '100%',
        maxWidth: '210px',
        height: '56px',
        borderRadius: '4px',
        padding: theme.spacing(1),
        margin: theme.spacing(1, 0),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
        },
    },
    btnAcessarText: {
        width: '100%',
        fontFamily: theme.typography.bancoDoBrasilTextos.fontFamily,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '46px',
        color: theme.appColors.primaryColor,
    },
    link: {
        fontFamily: theme.typography.inter.fontFamily,
        fontSize: '13px',
        lineHeight: '15px',
        color: theme.appColors.primaryColor,
        textDecoration: 'underline',
        cursor: 'pointer',
        margin: theme.spacing(1, 0),
    },
}));
