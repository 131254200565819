import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.appColors.primaryHeaderColor,
        alignItems: 'center',
    },
    containerGrid: {
        backgroundColor: theme.appColors.white,
        padding: '6vh 5vw',
        borderRadius: '8px',
        margin: theme.spacing(5, 0),
        [theme.breakpoints.only('xs')]: {
            padding: '6vh 4vw',
            margin: 0,
            borderRadius: '0px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '6vh 4vw',
        },
    },

    titulo: {
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        color: theme.appColors.purpleText,
        fontSize: 24,
        fontWeight: 500,
    },
    subTitulo: {
        color: theme.appColors.darkGray,
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        fontSize: 14,
        letterSpacing: -0.46,
        opacity: 0.699999988079071,
        lineHeight: '26px',
    },
    radioText: {
        color: theme.appColors.darkGray,
        fontSize: 14,
        margin: theme.spacing(2, 0),
    },

    radioGroup: {
        flexDirection: 'row',
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.appColors.primaryColor,
        },
    },
}));
