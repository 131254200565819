/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ACCESS_PORTAL_TEXT } from '../../../consts/text/form-login';
import { PortalAccessCards } from '../PortalAccessCards';
import { useStyles } from './styles';

export default function Home({ cmdAuth }) {
    const classes = useStyles();

    return (
        <>
            <Grid justifyContent="center" container>
                <Grid
                    justifyContent="center"
                    container
                    alignItems="center"
                    className={classes.gridContainer}
                >
                    <Grid direction="column" container>
                        <Typography className={classes.title}>
                            {ACCESS_PORTAL_TEXT.HOME_TITLE}
                        </Typography>
                        <Divider className={classes.underlineText} />
                    </Grid>

                    <Grid
                        className={classes.gridContainerDescription}
                        direction="column"
                        container
                    >
                        <Typography className={classes.description}>
                            {ACCESS_PORTAL_TEXT.HOME_SUB_TITLE1}
                        </Typography>
                        <Typography className={classes.description}>
                            {ACCESS_PORTAL_TEXT.HOME_SUB_TITLE2}
                        </Typography>
                    </Grid>
                </Grid>

                <PortalAccessCards cmdAuth={cmdAuth} />
            </Grid>
        </>
    );
}
