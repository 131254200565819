import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.appColors.backGroundColorPrimary,
        alignItems: 'center',
    },
    containerGrid: {
        height: '520px',
        maxWidth: '430px',
        backgroundColor: theme.appColors.white,
        padding: '44px 56px 80px',
        borderRadius: '8px',
        margin: '101px 0 80px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
            margin: 0,
            padding: '56px 21px 40px',
            borderRadius: '0px',
        },
    },
    titulo: {
        color: theme.appColors.purpleText,
        fontFamily: 'BancoDoBrasilTitulos',
        fontSize: '24px',
        lineHeight: '32px',
        maxWidth: '325px',
        textAlign: 'left',
        marginBottom: '25px',
    },
    subTitulo: {
        color: theme.appColors.darkGray,
        fontFamily: 'Inter',
        fontSize: '14px',
        letterSpacing: '-0.01px',
        lineHeight: '24px',
        width: '335px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
        },
    },
    imagemEsqueciMinhaSenha: {
        width: '139px',
        height: '130px',
        marginLeft: '-27px',
        [theme.breakpoints.down('xs')]: {
            width: '120px',
            height: '109px',
        },
    },
}));
