import crypto from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export function criptoToAES(data) {
    if(!data) return data;
    return crypto.AES.encrypt(`${data}`, SECRET_KEY).toString();
}

export function decriptFromAES(data) {
    if(!data) return data;
    const bytes = crypto.AES.decrypt(`${data}`, SECRET_KEY);
    return bytes.toString(crypto.enc.Utf8);
}
