import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FORM_LOGIN_LABELS } from '../../../consts/text/form-login';
import CardLoginForm from './CardLoginForm';
import { useStyles } from './styles';

const CardAccess = ({
    description,
    btnText,
    icon,
    href,
    title,
    showLoginForm,
    callback,
}) => {
    const classes = useStyles({ showLoginForm });
    return (
        <Grid
            direction="column"
            item
            container
            alignItems="center"
            className={classes.gridContainer}
            justifyContent="flex-start"
        >
            <Typography className={classes.title}>{title}</Typography>
            <Grid
                container
                item
                justifyContent="center"
                className={classes.gridIconContainer}
                alignItems="center"
            >
                <img
                    alt="Icone de acesso ao portal"
                    className={classes.icon}
                    src={icon}
                />
                <Typography className={classes.btnText}>{btnText}</Typography>
            </Grid>
            <Typography className={classes.description}>
                {description}
            </Typography>
            {showLoginForm ? (
                <CardLoginForm isNaoCorrentista />
            ) : (
                <Button
                    variant="contained"
                    color="secondary"
                    href={href}
                    className={classes.btnAcessar}
                    disableElevation
                    onClick={callback ? () => callback() : undefined}
                >
                    <Typography className={classes.btnAcessarText}>
                        {FORM_LOGIN_LABELS.BTN_ACESSAR}
                    </Typography>
                </Button>
            )}
        </Grid>
    );
};

export default CardAccess;
