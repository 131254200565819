//#region Imports

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import LGPD from '../../../consts/text/lgpd';
import { appColors } from './../../../AppTheme';
//#endregion

const {
    ATUALIZACAO_DADOS_CONTENT: {
        INFO,
        LIGAR_BB_SEGUROS,
        ATENDIMENTO,
        LIGAR_DEFICIENTES_AUDITIVOS,
    },
} = LGPD;

const AtualizacaoDados = () => {
    return (
        <Box display="flex" flexWrap="wrap" padding="2em 10%">
            <Box display="flex" flex="2" color={appColors.gray}>
                <Typography variant="subtitle1">{INFO}</Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                flex="1"
                marginLeft="10%"
            >
                <Box color={appColors.darkBlue}>
                    <Typography variant="subtitle1">
                        {LIGAR_BB_SEGUROS.THIS}
                    </Typography>
                    <Typography variant="h4">
                        {LIGAR_BB_SEGUROS.NUMERO}
                    </Typography>
                </Box>
                <Box color={appColors.gray} paddingY="10px">
                    <Typography variant="subtitle2">{`${ATENDIMENTO.THIS}`}</Typography>
                    <Typography variant="subtitle1">{`${ATENDIMENTO.HORARIO}`}</Typography>
                </Box>
                <Box color={appColors.gray}>
                    <Typography variant="subtitle2">{`${LIGAR_DEFICIENTES_AUDITIVOS.THIS}`}</Typography>
                    <Typography variant="subtitle1">{`${LIGAR_DEFICIENTES_AUDITIVOS.NUMERO}`}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default AtualizacaoDados;
