import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.appColors.primaryHeaderColor,
        alignItems: 'center',
    },

    containerGrid: {
        height: '520px',
        maxWidth: '430px',
        backgroundColor: theme.appColors.white,
        padding: '44px 56px 80px',
        borderRadius: '8px',
        margin: '101px 0 80px',
        [ theme.breakpoints.down( 'xs' ) ]: {
            height: '623px',
            width: '414px',
            maxWidth: '100%',
            margin: 0,
            padding: '56px 21px 48px',
            borderRadius: '0px',
        },
    },

    link: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.63',
        letterSpacing: 'normal',
        textAlign: 'left',
        textDecoration: 'underline',
        color: theme.appColors.primaryColor,
        fontFamily: 'Inter',
        '&:hover': {
            filter: 'brightness(80%)',
        },
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            fontWeight: 'bold',
        },
    },

    titulo: {
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        color: theme.appColors.purpleText,
        fontSize: 24,
        textAlign: 'left',
        fontWeight: 500,
    },
    subTitulo: {
        color: theme.appColors.darkGray,
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        fontSize: 14,
        letterSpacing: -0.46,
        opacity: 0.699999988079071,
        textAlign: 'left',
        lineHeight: '26px',
        marginBottom: theme.spacing(1),
    },

    button: {
        color: '#FFFFFF',
        fontFamily: 'BancoDoBrasilTitulos',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.66px',
        textAlign: 'center',
        marginTop: theme.spacing(1),
        width: 200,
        height: '48px',
        marginBottom: 20,
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
        },
    },
    input: {
        margin: '32px 0 38px',
    },
}));
