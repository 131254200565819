import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    containerForm: {
        maxWidth: 430,
        minWidth: 320,
        padding: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            padding: '40px 20px',
        },
    },
    title: {
        color: theme.appColors.purpleText,
        fontFamily: 'BancoDoBrasilTitulos',
        fontSize: '24px',
        lineHeight: '32px',
        paddingBottom: '8px',
    },
    text: {
        color: '#4C4C4C',
        fontFamily: 'Inter',
        fontSize: '14px',
        maxWidth: '300px',
        textAlign: 'left',
        paddingBottom: '32px',
    },
    cadastroImg: {
        width: '82px',
        height: '82px',
        paddingBottom: '16px',
    },
    fieldsGrid: {
        maxWidth: '100%',
    },
    fields: {
        paddingBottom: 32,
    },
    iconeVisibility: {
        color: theme.appColors.lightBlue,
    },
    information: {
        padding: '0 2px',
        color: theme.appColors.newGrey,
        fontFamily: 'Inter',
        fontSize: 14,
        letterSpacing: -0.58,
        '& > span': {
            fontWeight: 'bold',
        },
        marginBottom: 15,
    },
    button: {
        color: '#FFFFFF',
        fontFamily: 'BancoDoBrasilTitulos',
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.66px',
        textAlign: 'center',
        marginTop: theme.spacing(1),
        width: '158px',
        height: '48px',
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
        },
    },
}));
