export const CONFIRMACAO_FIELDS = {
    CODIGO_ACESSO: 'codigoAcesso',
};

export const CONFIRMACAO_LABELS = {
    CODIGO_ACESSO: 'Código de acesso*',
};

export const CONFIRMACAO_TXTS = {
    TITLE: (isPrimeiroAcesso) =>
        isPrimeiroAcesso ? 'Primeiro Acesso' : 'Confirmação de segurança',
    SUBTITLE: (hasCellphone) =>
        !hasCellphone
            ? 'Você receberá o código por e-mail.'
            : 'Como deseja receber seu código de acesso?',
    DESCRIPTION: (envio) => `Digite o código de acesso enviado via ${envio}:`,
    BUTTON: 'confirmar',
    NAO_RECEBI: 'Não recebi o código',
};
