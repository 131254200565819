import {
    Grid,
    IconButton,
    InputAdornment,
    Typography,
    Link,
} from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SenhaIcon } from '../../assets/icons/senha-icon.svg';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomInputForm from '../../components/CustomInputForm/CustomInputForm';
import {
    FORM_REDEFINIR_SENHA_FIELDS as rsFields,
    REDEFINIR_SENHA_LABELS as rsLabels,
} from '../../consts/text/redefinir-senha';
import { AutenticationActions } from '../../store/ducks/autentication';
import * as session from '../../utils/session';
import { formSchema } from './form-schema';
import { useStyles } from './styles';
import ROUTES_NAME from './../../consts/routes-name';
import { yupResolver } from '@hookform/resolvers';

registerLocale('pt-BR', ptBR);

const FormRedefinirSenha = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { state } = history.location;

    const [showSenha, setShowSenha] = useState(false);
    const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);

    const { errors, handleSubmit, register, getValues } = useForm({
        resolver: yupResolver(formSchema),
    });

    const { autenticacao } = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const getMessageCodigoAcessoEnviado = () => {
        if (state?.isPrimeiroAcesso) {
            return rsLabels.MENSAGEM_PRIMEIRO_ACESSO(state?.opcaoEnvio);
        }

        const emailAddr = autenticacao?.esqueciSenha?.emailAddr;

        return rsLabels.MENSAGEM_CODIGO_ACESSO(
            emailAddr || state?.dadoMascarado,
        );
    };

    const handleClickNaoRecebeuCodigo = () => {
        if (state?.isPrimeiroAcesso) {
            history.push(ROUTES_NAME.SELECAO_ENVIO_CODIGO_PRIMEIRO_ACESSO);
        } else {
            history.push(ROUTES_NAME.VALIDACAO, { esqueciSenha: true });
        }
    };

    const submit = (data) => {
        if (
            autenticacao?.esqueciSenha ||
            state?.isPrimeiroAcesso ||
            state?.esqueciSenha
        ) {
            const userName = session.getUserName();
            const userNamePrimeiroAcesso = session.getUserNamePrimeiroAcesso();

            if (!state?.isPrimeiroAcesso) {
                dispatch(
                    AutenticationActions.postAlterarSenhaRequest(
                        {
                            resetCode: data[rsFields.CODIGO_DE_ACESSO],
                            newPassword: data[rsFields.NOVA_SENHA],
                            verifyPassword: data[rsFields.CONFIRMAR_SENHA],
                            userName,
                            firstAccess: false,
                        },
                        state?.isPrimeiroAcesso,
                    ),
                );
            } else {
                dispatch(
                    AutenticationActions.postAlterarSenhaRequest(
                        {
                            ...data,
                            cpf: userNamePrimeiroAcesso,
                            resetCode: data[rsFields.CODIGO_DE_ACESSO],
                            newPassword: data[rsFields.NOVA_SENHA],
                            verifyPassword: data[rsFields.CONFIRMAR_SENHA],
                        },
                        state?.isPrimeiroAcesso,
                    ),
                );
            }
        }
    };

    useEffect(() => {
        if (
            !autenticacao?.esqueciSenha &&
            !state?.isPrimeiroAcesso &&
            !state?.esqueciSenha
        ) {
            history.push(ROUTES_NAME.HOME);
        }
    }, [autenticacao, history, state]);

    return (
        <Grid item container className={classes.containerForm}>
            <Grid item xs={12}>
                <SenhaIcon className={classes.cadastroImg} />
                <Typography className={classes.title}>
                    {rsLabels.TITLE}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography className={classes.text}>
                    {getMessageCodigoAcessoEnviado()}
                </Typography>
            </Grid>

            <form
                data-gtm-type="form"
                data-gtm-name="redefinir-senha"
                onSubmit={handleSubmit(submit)}
            >
                <Grid item container className={classes.fieldsGrid}>
                    <Grid item xs={12} className={classes.fields}>
                        <CustomInputForm
                            label={rsLabels.CODIGO_DE_ACESSO}
                            name={rsFields.CODIGO_DE_ACESSO}
                            inputProps={{ maxLength: 60 }}
                            inputRef={register}
                            shrink={Boolean(
                                getValues(rsFields.CODIGO_DE_ACESSO),
                            )}
                            data-gtm-form="input"
                            data-gtm-name="codigo-acesso"
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.fields}>
                        <CustomInputForm
                            name={rsFields.NOVA_SENHA}
                            placeholder={rsLabels.NOVA_SENHA}
                            label={rsLabels.NOVA_SENHA}
                            inputProps={{ maxLength: 60 }}
                            inputRef={register}
                            shrink={Boolean(getValues(rsFields.NOVA_SENHA))}
                            errors={errors}
                            type={showSenha ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowSenha(!showSenha)}
                                        className={classes.iconeVisibility}
                                    >
                                        {showSenha ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            data-gtm-form="input"
                            data-gtm-name="nova-senha"
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.fields}>
                        <CustomInputForm
                            name={rsFields.CONFIRMAR_SENHA}
                            placeholder={rsLabels.CONFIRMAR_SENHA}
                            label={rsLabels.CONFIRMAR_SENHA}
                            inputProps={{ maxLength: 60 }}
                            inputRef={register}
                            shrink={Boolean(
                                getValues(rsFields.CONFIRMAR_SENHA),
                            )}
                            errors={errors}
                            type={showConfirmarSenha ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            setShowConfirmarSenha(
                                                !showConfirmarSenha,
                                            )
                                        }
                                        className={classes.iconeVisibility}
                                    >
                                        {showConfirmarSenha ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            data-gtm-form="input"
                            data-gtm-name="confirmar-senha"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.information}>
                            {rsLabels.ORIENTACOES}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.fields}>
                        <CustomButton
                            className={classes.button}
                            content={rsLabels.BOTAO}
                            variant="contained"
                            type="submit"
                            gtm={{
                                id: 'salvar-codigo-acesso-001',
                                'data-gtm-name': 'salvar',
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <Link
                            component="button"
                            className={classes.link}
                            onClick={() => handleClickNaoRecebeuCodigo()}
                            data-gtm-type="click"
                            data-gtm-clicktype="button"
                            id="nao-recebeu-001"
                            data-gtm-name="clique aqui"
                        >
                            Não recebeu o código? Refaça a solicitação.
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

export default FormRedefinirSenha;
