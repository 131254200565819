import { Grid } from '@material-ui/core';
import React from 'react';
import FormRedefinirSenha from '../../containers/FormRedefinirSenha';
import { useStyles } from './styles';

export default function RedefinirSenhaPage() {
    const classes = useStyles();

    return (
        <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.container}
        >
            <Grid item container xs={12} className={classes.formContainer}>
                <FormRedefinirSenha />
            </Grid>
        </Grid>
    );
}
