import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    textBottomsheet: {
        fontSize: 14,
        letterSpacing: -0.2,
        color: theme.appColors.footerBlue,
        fontFamily: 'Inter',
        lineHeight: 1.4,
    },
    message: {
        color: '#4C4C4C',
        fontFamily: 'Inter',
        fontSize: 14,
        maxWidth: 510,
        textAlign: 'center',
    },
    modal: {
        width: '651px !important',
        height: '453px !important',
    },
}));
