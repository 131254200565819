import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ENDPOINTS } from '../../consts/endpoints';

/**
 *
 * @Footer Componente para exibicao de um Footerfixo da aplicação
 * @CriadoEm 24/01/2022
 */

export default function Footer() {
    const [iframeHeight, setIframeHeight] = React.useState('100%');

    useEffect(() => {
        const eventHandler = (e) => {
            if (e.origin !== ENDPOINTS.IFRAME_FOOTER) return;
            setIframeHeight(`${e.data}px`);
        };
        window.addEventListener('message', eventHandler);
        return () => window.removeEventListener('message', eventHandler);
    }, []);

    return (
        <Grid container>
            <iframe
                id="iframe"
                title="footer"
                src={ENDPOINTS.IFRAME_FOOTER}
                width="100%"
                style={{ border: '0', height: iframeHeight }}
            />
        </Grid>
    );
}
