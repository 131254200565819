import { yupResolver } from '@hookform/resolvers';
import {
    Grid,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SenhaIcon } from '../../assets/icons/senha-icon.svg';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomInputForm from '../../components/CustomInputForm/CustomInputForm';
import ROUTES_NAME from '../../consts/routes-name';
import PRIMEIRO_ACESSO from '../../consts/text/primeiro-acesso';
import {
    FORM_REDEFINIR_SENHA_FIELDS as rsFields,
    REDEFINIR_SENHA_LABELS as rsLabels,
} from '../../consts/text/redefinir-senha';
import { AutenticationActions } from '../../store/ducks/autentication';
import { formatDateBrToUsIso } from '../../utils/formatters';
import { onlyNums } from '../../utils/normalizers/normalizers';
import * as session from '../../utils/session';
import { formSchema } from './form-schema';
import { useStyles } from './styles';

registerLocale('pt-BR', ptBR);

const FormConfirmarSenhaPrimeiroAcesso = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { state } = history.location;

    const [showSenha, setShowSenha] = useState(false);
    const [showConfirmarSenha, setShowConfirmarSenha] = useState(false);

    const { errors, handleSubmit, register, getValues } = useForm({
        resolver: yupResolver(formSchema),
    });

    const submit = (data) => {
        const userNamePrimeiroAcesso = session.getUserNamePrimeiroAcesso();
        const dataPrimeiroAcesso = session.getDataPrimeiroAcesso();
        const tpPessoa =
            onlyNums(userNamePrimeiroAcesso).length > 11 ? 'PJ' : 'PF';
        dispatch(
            AutenticationActions.postPrimeiroAcessoRequest({
                cpfCnpj: onlyNums(userNamePrimeiroAcesso),
                data:
                    tpPessoa === 'PF'
                        ? formatDateBrToUsIso(dataPrimeiroAcesso)
                        : null,
                senha: data[rsFields.NOVA_SENHA],
                tipoPessoa: tpPessoa,
            }),
        );
    };

    useEffect(() => {
        if (!state?.isPrimeiroAcesso) {
            history.push(ROUTES_NAME.HOME);
        }
    }, [history, state]);

    return (
        <Grid item container className={classes.containerForm}>
            <Grid item xs={12}>
                <SenhaIcon className={classes.cadastroImg} />
                <Typography className={classes.title}>
                    {PRIMEIRO_ACESSO.CRIE_SUA_SENHA}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.text}>
                    {PRIMEIRO_ACESSO.TXT_CRIE_SUA_SENHA}
                </Typography>
            </Grid>

            <form onSubmit={handleSubmit(submit)}>
                <Grid item container className={classes.fieldsGrid}>
                    <Grid item xs={12} className={classes.fields}>
                        <CustomInputForm
                            name={rsFields.NOVA_SENHA}
                            placeholder={rsLabels.NOVA_SENHA}
                            label={rsLabels.NOVA_SENHA}
                            inputProps={{ maxLength: 60 }}
                            inputRef={register}
                            shrink={Boolean(getValues(rsFields.NOVA_SENHA))}
                            errors={errors}
                            data-gtm-form="input"
                            data-gtm-name="nova-senha"
                            type={showSenha ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowSenha(!showSenha)}
                                        className={classes.iconeVisibility}
                                    >
                                        {showSenha ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.fields}>
                        <CustomInputForm
                            name={rsFields.CONFIRMAR_SENHA}
                            placeholder={rsLabels.CONFIRMAR_SENHA}
                            label={rsLabels.CONFIRMAR_SENHA}
                            inputProps={{ maxLength: 60 }}
                            inputRef={register}
                            shrink={Boolean(
                                getValues(rsFields.CONFIRMAR_SENHA),
                            )}
                            errors={errors}
                            data-gtm-form="input"
                            data-gtm-name="confirmar-senha"
                            type={showConfirmarSenha ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            setShowConfirmarSenha(
                                                !showConfirmarSenha,
                                            )
                                        }
                                        className={classes.iconeVisibility}
                                    >
                                        {showConfirmarSenha ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.information}>
                            {rsLabels.ORIENTACOES}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.fields}>
                        <CustomButton
                            className={classes.button}
                            content={rsLabels.BOTAO}
                            variant="contained"
                            type="submit"
                            gtm={{
                                id: 'salvar-senha-001',
                                'data-gtm-name': 'salvar',
                            }}
                        />
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

export default FormConfirmarSenhaPrimeiroAcesso;
