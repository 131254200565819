/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers';
import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputForm from '../../../../components/CustomInputForm/CustomInputForm';
import ROUTES_NAME from '../../../../consts/routes-name';
import {
    FORM_LOGIN_FIELDS,
    FORM_LOGIN_LABELS,
} from '../../../../consts/text/form-login';
import HOME from '../../../../consts/text/home';
import { AutenticationActions } from '../../../../store/ducks/autentication';
import history from '../../../../utils/history';
import * as session from '../../../../utils/session';
import { formSchemaLogin } from './form-schema';
import { useStyles } from './styles';

const CardLoginForm = ({ isNaoCorrentista = false }) => {
    const classes = useStyles({ isNaoCorrentista });
    const dispatch = useDispatch();

    const { errors, handleSubmit, register, reset } = useForm({
        defaultValues: {
            [FORM_LOGIN_FIELDS.FIELD_EMAIL]: '',
        },
        resolver: yupResolver(formSchemaLogin),
    });

    const [showPassword, setShowPassword] = useState(false);
    const { autenticacao } = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const onClickShowPassword = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };

    const submitForm = (formData) => {
        session.setUserName(formData[FORM_LOGIN_FIELDS.FIELD_EMAIL]);
        const data = {
            ...formData,
            user_name: formData[FORM_LOGIN_FIELDS.FIELD_EMAIL],
        };
        dispatch(AutenticationActions.postLoginBBOpinRequest({ data }));
    };

    useEffect(() => {
        if (autenticacao?.opcaoEnvio) history.push(ROUTES_NAME.VALIDACAO);
    }, [autenticacao.opcaoEnvio]);

    useEffect(() => {
        setTimeout(() => {
            reset();
        }, 100);
    }, []);

    return (
        <Grid item direction="column" alignItems="center" container spacing={2}>
            <Grid container item>
                <CustomInputForm
                    name={FORM_LOGIN_FIELDS.FIELD_EMAIL}
                    inputRef={register}
                    checkValid
                    label={FORM_LOGIN_LABELS.LABEL_EMAIL}
                    errors={errors}
                />
            </Grid>

            <Grid container item>
                <CustomInputForm
                    errors={errors}
                    inputRef={register}
                    label={FORM_LOGIN_LABELS.LABEL_SENHA}
                    name={FORM_LOGIN_FIELDS.FIELD_SENHA}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                className={classes.iconeVisibility}
                                onClick={onClickShowPassword}
                                aria-label="toggle password visibility"
                            >
                                {showPassword ? (
                                    <VisibilityOutlinedIcon />
                                ) : (
                                    <VisibilityOffOutlinedIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>

            <Button
                variant="contained"
                color="secondary"
                onClick={handleSubmit(submitForm)}
                disableElevation
                className={classes.btnAcessar}
            >
                <Typography className={classes.btnAcessarText}>
                    {FORM_LOGIN_LABELS.BTN_ACESSAR}
                </Typography>
            </Button>

            <Typography
                onClick={() => history.push(ROUTES_NAME.ESQUECI_SENHA)}
                className={classes.link}
                component="a"
            >
                {HOME.NAO_CORRENTISTA.ESQUECI_SENHA}
            </Typography>

            <Typography
                onClick={() => history.push(ROUTES_NAME.PRIMEIRO_ACESSO)}
                className={classes.link}
                component="a"
            >
                {HOME.PRIMEIRO_ACESSO}
            </Typography>
        </Grid>
    );
};

export default CardLoginForm;
