import { yupResolver } from '@hookform/resolvers';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CustomButton from '../../components/CustomButton/CustomButton';
import { AutenticationActions } from '../../store/ducks/autentication';
import CustomInputForm from './../../components/CustomInputForm/CustomInputForm';
import {
    CONFIRMACAO_FIELDS,
    CONFIRMACAO_LABELS,
    CONFIRMACAO_TXTS,
} from './../../consts/text/confirmacao-seguranca';
import { formSchema } from './form-schema';
import { useStyles } from './styles';
import * as session from '../../utils/session';
import { onlyNums } from '../../utils/normalizers/normalizers';

export default function FormConfirmacaoSeguranca({ isPrimeiroAcesso }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(formSchema),
    });
    const onSubmit = (data) => {
        if (isPrimeiroAcesso) {
            const userName = session.getUserNamePrimeiroAcesso();
            dispatch(
                AutenticationActions.postValidarTokenPrimeiroAcessoRequest({
                    cpfCnpj: userName,
                    token: data[CONFIRMACAO_FIELDS.CODIGO_ACESSO],
                    tipoPessoa: onlyNums(userName).length > 11 ? 'PJ' : 'PF',
                }),
            );
        } else {
            dispatch(
                AutenticationActions.postValidarTokenRequest({
                    userName: session.getUserName(),
                    token: data[CONFIRMACAO_FIELDS.CODIGO_ACESSO],
                }),
            );
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <CustomInputForm
                        inputRef={register}
                        customClass={classes.input}
                        errors={errors}
                        label={CONFIRMACAO_LABELS.CODIGO_ACESSO}
                        name={CONFIRMACAO_FIELDS.CODIGO_ACESSO}
                        data-gtm-form="input"
                        data-gtm-name="codigo-de-acesso"
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomButton
                        className={classes.button}
                        content={CONFIRMACAO_TXTS.BUTTON}
                        variant="contained"
                        type="submit"
                        gtm={{
                            id: 'salvar-codigo-acesso-001',
                            'data-gtm-name': 'salvar',
                        }}
                    />
                </Grid>
            </Grid>
        </form>
    );
}
