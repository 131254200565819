import { Grid } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { ENDPOINTS } from '../../consts/endpoints';
import {LogoBBSegurosHeader} from '../../assets/images';

/**
 *
 * @Header Componente para exibicao de um Header fixo da aplicação
 * @CriadoEm 29/04/2020
 */

export default function Header() {
    const classes = useStyles();

    return (
        <div className={classes.divContainer}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.gridContainer}
            >

                <LogoBBSegurosHeader
                    className={classes.logo}
                    onClick={() =>
                        window.open(ENDPOINTS.URL_BBSEGUROS, '__blank')
                    }
               />

            </Grid>
        </div>
    );
}
