import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.appColors.primaryHeaderColor,
        alignItems: 'center',
    },
    containerGrid: {
        backgroundColor: theme.appColors.white,
        padding: '6vh 5vw',
        borderRadius: '8px',
        margin: theme.spacing(5, 0),
        [theme.breakpoints.only('xs')]: {
            padding: '6vh 4vw',
            margin: 0,
            borderRadius: '0px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '6vh 4vw',
        },
    },
}));
