import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: '999999 !important',
        '& .MuiDialog-paperScrollBody': {
            verticalAlign: 'bottom',
        },
    },
    container: {
        padding: theme.spacing(3),
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },

    paperFullScreen: {
        height: 'auto',
    },

    scrollPaper: {
        alignItems: 'flex-end',
    },

    close: {
        padding: theme.spacing(3, 3, 0, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
    },

    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.appColors.purpleText,
        fontSize: 20,
        fontFamily: 'BancoDoBrasilTitulos',
        fontWeight: 'bold',
        margin: theme.spacing(2, 0),
        textAlign: 'center',
    },

    subTitle: {
        color: '#4C4C4C',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 500,
        textAlign: 'center',
    },

    content: {
        color: theme.palette.primary.main,
        fontFamily: 'Inter',
        fontSize: 14,
        letterSpacing: '-0.2px',
        lineHeight: '20px',
        margin: '15px 0',
    },

    icon: {
        padding: 0,
        color: theme.palette.primary.main,
    },

    button: {
        width: '100%',
        fontFamily: 'BancoDoBrasilTitulos',
        fontSize: 15,
        marginBottom: theme.spacing(2),
    },
}));
