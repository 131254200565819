import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

function ButtonSubmit({ disabled, isLoading }) {
    return (
        <Button
            variant="contained"
            color="primary"
            component="button"
            type="submit"
            disabled={disabled}
            endIcon={
                isLoading ? (
                    <CircularProgress color="primary" size={14} />
                ) : undefined
            }
        >
            Enviar
        </Button>
    );
}

export default ButtonSubmit;
