/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Home from '../home';
import axios from 'axios';
import { opinConsentPage as OCP } from '../const';

export default function AuthConsent({ history }) {
    const [cmdAuth, setCmdAuth] = useState();
    const { hash } = useLocation();
    const commandId = hash?.replace('#', '');

    const api = axios.create({
        baseURL: OCP.baseURL,
    });

    const getConsent = useCallback(async () => {
        await api
            .get(`/auth/app/commands/${commandId}`)
            .then((response) => {
                setCmdAuth(response.data);
                localStorage.setItem('commandId', commandId);
            })
            .catch((error) => {
                // history.push('/home');
                return error;
            });
    }, []);

    useEffect(() => {
        localStorage.clear();
        getConsent();
    }, []);

    return <>{cmdAuth ? <Home cmdAuth={cmdAuth} /> : null}</>;
}
