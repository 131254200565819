import React from 'react';

export const ESQUECI_SENHA_LABELS = {
    NAME_CPF: 'cpf',
    LABEL_CPF: 'CPF*',
    BTN: 'Enviar',
    TITLE: 'Esqueci minha senha',
    DESCRIPTION: 'Informe seu CPF para receber o código',
    EMAIL_ENVIADO: (email) => (
        <>
            Um e-mail com o código de acesso foi enviado para
            <br /> {email}
        </>
    ),
    REDEFINIR_SENHA: 'Redefinir a senha',
    CRIE_NOVA_SENHA: 'Crie uma nova senha.',
};
