import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    divContainer: {
        height: 80,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: theme.appColors.primaryYellow,
    },
    gridContainer: {
        padding: theme.spacing(3, 3),
        width: '100%',
        alignItems: 'center',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            width: '88%',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    logo: {
        cursor: 'pointer',
        maxWidth: '140px',
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '135px',

        },
        '& :hover':{

            filter: 'brightness(90%)',

        },
    },
    headerItem: {
        position: 'relative',
        [theme.breakpoints.down('xl')]: {
            color: theme.appColors.primaryHeaderColor,
            cursor: 'pointer',
        },
        [theme.breakpoints.down('md')]: {
            marginRight: 15,
        },
    },
    headerItemIcon: {
        color: theme.appColors.footerBlue,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
    },
    button: {
        whiteSpace: 'nowrap',
        color: theme.appColors.primaryBlue,
        width: 100,
        height: 32,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
        width: 81,
    },
    containerAcessos: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    divider: {
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1),
            marginTop: -20,
            marginBottom: -15,
        },
    },

    containerAreaCliente: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
    },
}));
