import { PROPONENTE_FIELDS } from '../proponente';

export const FORM_DADOS_PESSOAIS = {
    NOME: 'Nome *',
    SOBRENOME: 'Sobrenome *',
    EMAIL: 'E-mail',
    CPF: 'CPF *',
    CNPJ: 'CNPJ *',
    DATA_NASCIMENTO: 'Data de nascimento *',
    CELULAR: 'Celular',
    NOME_PESSOA_RAZAO_SOCIAL: 'Como quer ser chamado?',
    DADOS_PESSOAIS: 'Dados Pessoais',
    CADASTRO: 'Cadastro',
    PESSOA_FISICA: 'Pessoa Física',
    PESSOA_JURIDICA: 'Pessoa Jurídica',
};

export const FORM_DADOS_PESSOAIS_FIELDS = {
    NOME: PROPONENTE_FIELDS.NOME_PESSOA,
    SOBRENOME: PROPONENTE_FIELDS.SOBRENOME,
    EMAIL: PROPONENTE_FIELDS.NOME_EMAIL,
    CPF_CNPJ: PROPONENTE_FIELDS.CPF_OU_CNPJ,
    DATA_NASCIMENTO: PROPONENTE_FIELDS.DATA_NASCIMENTO,
    DATA: PROPONENTE_FIELDS.DATA,
    CELULAR: PROPONENTE_FIELDS.NUMERO_TELEFONE,
    NOME_PESSOA_CONTEUDO: PROPONENTE_FIELDS.NOME_PESSOA_CONTEUDO,
    CPF_OU_CNPJ_CONTEUDO: PROPONENTE_FIELDS.CPF_OU_CNPJ_CONTEUDO,
    NOME_PESSOA_RAZAO_SOCIAL: PROPONENTE_FIELDS.NOME_PESSOA_RAZAO_SOCIAL,
};
