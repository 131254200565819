import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { FORM_LOGIN_LABELS } from '../../consts/text/form-login';
import CardLoginForm from './CardLoginForm';
import { useStyles } from './styles';

const CardAccess = ({
    btnText,
    description,
    href,
    icon,
    showLoginForm,
    title,
    gtm,
}) => {
    const classes = useStyles({ showLoginForm });

    const handleClick = (href) => {
        window.open(href, '_blank');
    };

    return (
        <Grid
            item
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.gridContainer}
        >
            <Typography className={classes.title}>{title}</Typography>

            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.gridIconContainer}
            >
                <img
                    src={icon}
                    className={classes.icon}
                    alt="Icone de acesso ao portal"
                />
                <Typography className={classes.btnText}>{btnText}</Typography>
            </Grid>

            <Typography className={classes.description}>
                {description}
            </Typography>

            {showLoginForm ? (
                <CardLoginForm isNaoCorrentista gtm={gtm} />
            ) : (
                <Button
                    color="secondary"
                    variant="contained"
                    className={classes.btnAcessar}
                    disableElevation
                    onClick={() => handleClick(href)}
                    {...gtm}
                >
                    <Typography className={classes.btnAcessarText} {...gtm}>
                        {FORM_LOGIN_LABELS.BTN_ACESSAR}
                    </Typography>
                </Button>
            )}
        </Grid>
    );
};

export default CardAccess;
