import FIELDS from '../../../../library/acesso-dados-fields';
import appYup from '../../../../library/validation/validation';

const {
    EMAIL,
    CORRENSPONDENCIA,
    CONSULTA_DADOS: { INFO_ADICONAIS },
} = FIELDS;

const formConsultaDataSchema = appYup.object().shape(
    {
        [EMAIL]: appYup.string().when(CORRENSPONDENCIA, {
            is: (val) => !val,
            then: appYup.string().required().email(),
            otherwise: appYup.string().email(),
        }),
        [CORRENSPONDENCIA]: appYup.string().when(EMAIL, {
            is: (val) => !val,
            then: appYup.string().required().max(400),
            otherwise: appYup.string().max(400),
        }),
        [INFO_ADICONAIS]: appYup.string().max(400),
    },
    [EMAIL, CORRENSPONDENCIA]
);

export default formConsultaDataSchema;
