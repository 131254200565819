//#region Imports

import "./fonts.css";

//#endregion

export const FONTS = {
    ArialMT: {
        fontFamily: "ArialMTBold",
    },
    ArialMTLight: {
        fontFamily: "ArialMTLight",
    },
    ArialMTBold: {
        fontFamily: "ArialMTBold",
    },
};

export default FONTS;
