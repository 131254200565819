const PRIMEIRO_ACESSO = {
    TITLE: 'Primeiro acesso',
    DESCRIPTION: 'Por favor, preencha os dados solicitados:',
    BUTTON: 'Continuar',
    CRIE_SUA_SENHA: 'Crie sua senha',
    TXT_CRIE_SUA_SENHA:
        'Sua senha deve conter 8 digitos meclando números, letras e caracteres especiais.',
};

export default PRIMEIRO_ACESSO;
