import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useStyles } from './styles';
import FormLogin from '../../containers/FormLogin';

export default function Login() {
    const classes = useStyles();

    return (
        <Grid
            container
            className={classes.root}
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                sm={7}
                md={5}
                xl={3}
                square
                elevation={0}
                component={Paper}
                className={classes.containerGrid}
            >
                <Grid item container justifyContent="center">
                    <Grid
                        item
                        container
                        justifyContent="flex-start"
                        direction="row"
                        alignItems="center"
                        spacing={3}
                    >
                        <FormLogin />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
