const ACESSO_DADOS_FIELDS = {
    TOKEN: 'token',
    EMAIL: 'email',
    CORRENSPONDENCIA: 'enderecoCorrespondencia',
    CONSULTA_DADOS: {
        RECEBER_COPIA: 'receberUmaCopiaDosDados',
        INFO_ADICONAIS: 'informacoesAdicionais',
    },
    OUTRAS_SOLICITACOES: {
        ELIMINAR: 'eliminarDadoPessoal',
        VALOR_ELIMINAR: 'valorEliminarDadoPessoal',
        BLOQUEAR: 'bloquearDadoPessoal',
        VALOR_BLOQUEAR: 'valorBloquearDadoPessoal',
        NAO_RECEBER_INFO: 'naoReceberInformacoesPublicitarias',
    },
};

export default ACESSO_DADOS_FIELDS;
