const URI = process.env.REACT_APP_APIGATEWAY_URI;

export const opinConsentPage = {
    baseURL: `https://opin.bbseguros.com.br`,
};

export const opinHomePage = {
    redirectURL: `https://security-oauth.bbseguros.com.br/loginoauthbbopin`,
    clientId: `eyJpZCI6ImI3YWNmYWEtNGVlOC0iLCJjb2RpZ29QdWJsaWNhZG9yIjowLCJjb2RpZ29Tb2Z0d2FyZSI6MzEzNjYsInNlcXVlbmNpYWxJbnN0YWxhY2FvIjoxfQ`,
    scope: `openid+cpf+profile`,
    responseType: `code`,
    authURL: `https://oauth.bb.com.br/ui/authorize`,
};

export const loginOAuthBBOpinPage = {
    apiUrl: `${URI}/oauth/login?APP_NAME=cg-user-app`,
    bbCorrentistaURL: `${URI}/api/opin-area-logada-backend/login-oauthbb`,
    bbNaoCorrentistaURL: `${URI}/api/opin-area-logada-backend/login-nao-correntista`,
};
