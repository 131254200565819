import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: theme.spacing(6, 3, 0),
        backgroundColor: theme.appColors.newGreySecondary,
        maxWidth: '381px',
        width: '381px',
        height: '559px',
        maxHeight: '559px',
        borderRadius: '4px',
        border: `1px solid ${theme.appColors.lightPurple}`,

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2, 0),
            width: '263px',
        },
    },
    gridIconContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    title: {
        fontFamily: theme.typography.bancoDoBrasilTextos.fontFamily,
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '16px',
        color: theme.appColors.bgBlue,
        marginBottom: theme.spacing(5),

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            marginBottom: theme.spacing(3),
            width: '80%',
        },
    },
    btnText: {
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        fontSize: '18px',
        lineHeight: '19px',
        color: theme.appColors.bgBlue,
        textTransform: 'none',
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
        maxWidth: '202px',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    description: ({ showLoginForm }) => ({
        fontFamily: theme.typography.bancoDoBrasilTextos.fontFamily,
        fontSize: '16px',
        lineHeight: '18px',
        color: theme.appColors.darkGray,
        textAlign: 'center',
        letterSpacing: 0,
        margin: showLoginForm ? theme.spacing(5, 0, 2) : theme.spacing(7, 0),
        padding: theme.spacing(0, 3),

        [theme.breakpoints.down('sm')]: {
            margin: showLoginForm ? theme.spacing(2, 0) : theme.spacing(7, 0),
        },
    }),
    btnAcessar: {
        width: '100%',
        maxWidth: '210px',
        height: '56px',
        borderRadius: '4px',
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    btnAcessarText: {
        fontFamily: theme.typography.bancoDoBrasilTextos.fontFamily,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '46px',
        color: theme.appColors.primaryColor,
    },
    icon: {
        width: 48,
        height: 48,
        marginRight: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
}));
