import * as Yup from 'yup';
import {
    validateCEP,
    validateCompare,
    validateCPFAndCNPJ,
    validatePhone,
    validateCellPhone,
    validateDate,
    validateCompanyDate,
    validateNomeSegurado,
} from './custom-validations';
import { VALIDATION_MESSAGES as validationMessages } from './validation-msg';

const compare = (valueToCompare, message) =>
    Yup.string().test(
        'compare',
        message || validationMessages.COMPARE,
        validateCompare(valueToCompare),
    );

const cpf = (message) =>
    Yup.string().test(
        'cpfCnpj',
        message || validationMessages.CPF,
        validateCPFAndCNPJ,
    );

const cnpj = (message) =>
    Yup.string().test(
        'cpfCnpj',
        message || validationMessages.CNPJ,
        validateCPFAndCNPJ,
    );

const cep = (message) =>
    Yup.string().test(
        'cpfCnpj',
        message || validationMessages.CEP,
        validateCEP,
    );

const telefoneAndCelular = (message) =>
    Yup.string().test(
        'telefoneAndCelular',
        message || validationMessages.TELEFONE,
        validatePhone,
    );

const celular = (message) =>
    Yup.string().test(
        'celular',
        message || validationMessages.TELEFONE,
        validateCellPhone,
    );

const customValidateDate = (message) =>
    Yup.string().test(
        'customValidateDate',
        message || validationMessages.DATA_INVALIDA,
        validateDate,
    );

const customValidateCompanyDate = (message) =>
    Yup.string().test(
        'dataEmpresa',
        message || validationMessages.DATA_INVALIDA,
        validateCompanyDate,
    );

const nomeSegurado = () => {
    return Yup.string().test(
        'nomeSegurado',
        'Por favor, digite seu nome completo.',
        validateNomeSegurado,
    );
};

const dataCartao = () => {
    return Yup.string()
        .length(5, 'O campo é obrigatório.')
        .test('dataCartao', 'Data Inválida', (value) => {
            const [mes, ano] = value.split('/');
            return (
                Number(mes) <= 12 &&
                Number(20 + ano) >= new Date().getFullYear()
            );
        });
};

Yup.addMethod(Yup.string, 'cep', cep);
Yup.addMethod(Yup.string, 'nomeSegurado', nomeSegurado);
Yup.addMethod(Yup.string, 'telefoneAndCelular', telefoneAndCelular);
Yup.addMethod(Yup.string, 'celular', celular);
Yup.addMethod(Yup.string, 'customValidateDate', customValidateDate);
Yup.addMethod(
    Yup.string,
    'customValidateCompanyDate',
    customValidateCompanyDate,
);
Yup.addMethod(Yup.string, 'cpf', cpf);
Yup.addMethod(Yup.string, 'cnpj', cnpj);
Yup.addMethod(Yup.string, 'compare', compare);
Yup.addMethod(Yup.string, 'dataCartao', dataCartao);

Yup.setLocale({
    mixed: {
        default: validationMessages.DEFAULT,
        required: validationMessages.REQUIRED,
        notType: validationMessages.DEFAULT,
    },
    number: {
        min: validationMessages.DEVE_SER_MAIOR,
    },
    string: {
        email: validationMessages.EMAIL,
        min: validationMessages.MIN_LENGTH,
        max: validationMessages.MAX_LENGTH,
        cep: validationMessages.CEP,
    },
    date: {
        default: validationMessages.DATA_INVALIDA,
        required: validationMessages.REQUIRED,
    },
});

export default Yup;
