import React, { forwardRef } from 'react';
import { Dialog, Grid, IconButton, Slide, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStyles } from './styles';
import { ReactComponent as IconErroPrimeiroAcesso } from '../../assets/icons/iconErroPrimeiroAcesso.svg';

import CustomButton from '../CustomButton/CustomButton';

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomBottomSheet({
    open,
    handleClose,
    buttonText,
    body,
    title,
    subTitle,
    onClickButton,
}) {
    const classes = useStyles();

    // const cobertura = title?.props?.children;

    const handleClickClose = () => {
        handleClose();
    };

    return (
        <Dialog
            fullScreen
            keepMounted
            open={open}
            TransitionComponent={Transition}
            scroll="body"
            classes={{
                root: classes.root,
                paper: classes.paper,
                scrollPaper: classes.scrollPaper,
                paperFullScreen: classes.paperFullScreen,
            }}
        >
            <Grid item xs={12} className={classes.close}>
                <IconButton className={classes.icon}>
                    <Close onClick={handleClickClose} />
                </IconButton>
            </Grid>
            <Grid container direction="column" className={classes.container}>
                <Grid item xs={12}>
                    <IconErroPrimeiroAcesso />
                </Grid>
                {title && (
                    <Grid item xs={12}>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                    </Grid>
                )}
                {subTitle && (
                    <Typography className={classes.subTitle}>
                        {subTitle}
                    </Typography>
                )}

                {body && (
                    <Grid item xs={12} className={classes.content}>
                        {body}
                    </Grid>
                )}

                {onClickButton && (
                    <Grid item xs={12}>
                        <CustomButton
                            fullWidth
                            content={buttonText}
                            className={classes.button}
                            onClickCallBack={onClickButton}
                        />
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
}
