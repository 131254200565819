/* eslint-disable no-template-curly-in-string */
export const VALIDATION_MESSAGES = {
    DEFAULT: 'Campo inválido',
    REQUIRED: 'O campo é obrigatório',
    EMAIL: 'E-mail é inválido',
    MAX_LENGTH: 'O máximo de caracteres permitidos é ${max}',
    MIN_LENGTH: 'O mínimo de caracteres permitidos é ${min}',
    CPF: 'CPF inválido',
    CNPJ: 'CNPJ inválido',
    CPF_CNPJ: 'CPF inválido',
    CEP: 'CEP inválido',
    TELEFONE: 'Telefone inválido',
    DATA_INVALIDA: 'Data inválida',
    CONFIRME_SENHA_VALIDA: 'Confirme uma senha válida',
    INSIRA_SENHA_VALIDA: 'Insira uma senha válida',
    COMPARE: 'As senhas não coincidem, verifique e tente novamente. ',
    SEM_NUMERO:
        'Este campo deve ser preenchido somente com números. Caso seu endereço não possua número, preencher com 0',
};
