import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        width: '80%',
    },
    containerTitulos: {
        margin: '20px 0px 20px 0px',
    },

    titulo: {
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        color: theme.appColors.purpleText,
        fontSize: 24,
        textAlign: 'center',
        fontWeight: 500,
    },
    subTitulo: {
        color: theme.appColors.darkGray,
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        fontSize: 14,
        letterSpacing: -0.46,
        opacity: 0.699999988079071,
        textAlign: 'center',
        lineHeight: '26px',
    },

    link: {
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.63',
        letterSpacing: 'normal',
        textAlign: 'left',
        textDecoration: 'underline',
        color: theme.appColors.primaryColor,
        fontFamily: 'Inter',
        '&:hover': {
            filter: 'brightness(80%)',
        },
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            fontWeight: 'bold',
        },
    },
    criarTxt: {
        fontSize: '16px',
        fontWeight: 'normal',
        marginBottom: theme.spacing(2),
        '& button': {
            fontSize: '16px',
            color: theme.appColors.blue,
            textDecoration: 'underline',
        },
    },
    Button: {
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 1,
        textAlign: 'center',
        marginTop: theme.spacing(1),
        minWidth: 200,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '48px',
        },
    },
    titleModal: {
        fontSize: 24,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
    },
    desciptionModal: {
        fontSize: 20,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.4,
        letterSpacing: 0.15,
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: 260,
        },
    },

    iconeVisibility: {
        color: theme.appColors.lightBlue,
    },
    cadastroTxt: {
        fontFamily: 'Inter',
        fontSize: 14,
        letterSpacing: -0.58,
    },

    errorMessage: {
        color: theme.appColors.errorRed,
        fontFamily: 'Inter',
        margin: 10,
    },
}));
