import { Yup } from '../../utils';
import { FORM_REDEFINIR_SENHA_FIELDS as rsf } from '../../consts/text/redefinir-senha';
import { VALIDATION_MESSAGES } from '../../utils/validations/validation-msg';

export const formSchema = Yup.object().shape({
    [rsf.CONFIRMAR_SENHA]: Yup.string()
        .matches(
            /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
            VALIDATION_MESSAGES.CONFIRME_SENHA_VALIDA,
        )
        .oneOf([Yup.ref(rsf.NOVA_SENHA)], VALIDATION_MESSAGES.COMPARE)
        .required(),
    [rsf.NOVA_SENHA]: Yup.string()
        .matches(
            /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
            VALIDATION_MESSAGES.INSIRA_SENHA_VALIDA,
        )
        .oneOf([Yup.ref(rsf.CONFIRMAR_SENHA)], VALIDATION_MESSAGES.COMPARE)
        .required(),
});
