export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Localiza um atributo em um Objeto, de forma nested.
 * Ex.: beneficiario[0].nome
 */
function locate(obj, attr) {
    if (typeof attr === 'string') {
        attr = attr.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        attr = attr.replace(/^\./, ''); // strip a leading dot
        attr = attr.split('.');

        for (let i = 0; i < attr.length; ++i) {
            const name = attr[i];
            if (obj && !(name in obj)) {
                return;
            }

            obj = (obj || {})[name];
        }
    }

    return obj;
}

/**
 * Busca um erro dado um name/path no objeto de erro do react hook form
 */
const getHookFormError = (errors, name = '') => {
    const isDeepSearch = name.indexOf('.') !== -1 || name.indexOf('[') !== -1;
    const errorObj = isDeepSearch ? locate(errors, name) : errors[name];
    return (errorObj || {}).message;
};

/**
 * Gera o name e o objeto de error de um Field
 */
export function genFieldNameAndError(errors, name = '') {
    const errorMessage = getHookFormError(errors, name);

    return { name, error: Boolean(errorMessage), helperText: errorMessage };
}
