import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.appColors.primaryHeaderColor,
        alignItems: 'center',
    },
    divider: {
        height: '1px',
        marginTop: '20px',
        marginBottom: '20px',
        width: '120%',
        flexShrink: '0',
        backgroundColor: theme.appColors.newGrey,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
    containerGrid: {
        backgroundColor: theme.appColors.white,
        padding: '6vh 5vw',
        borderRadius: '8px',
        margin: theme.spacing(5, 0),
        [theme.breakpoints.only('xs')]: {
            padding: '6vh 4vw',
            margin: 0,
            borderRadius: '0px',
        },
        [theme.breakpoints.only('sm')]: {
            padding: '6vh 4vw',
        },
    },
    spacing: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 200,
    },
    titulo: {
        color: theme.appColors.purpleText,
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        fontSize: 24,
        lineHeight: '32px',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    subTitulo: {
        color: theme.appColors.darkGray,
        fontFamily: theme.typography.bancoDoBrasilTextos.fontFamily,
        fontSize: 14,
        letterSpacing: '-0.46px',
        lineHeight: '26px',
        textAlign: 'left',
        opacity: 0.699999988079071,
    },
    tituloLoginAssist: {
        fontFamily: theme.typography.inter.fontFamily,
        color: theme.appColors.newGrey,
        fontSize: 16,
        textAlign: 'left',
        fontWeight: 500,
    },
    tituloLoginBB: {
        color: theme.appColors.newGrey,
        fontFamily: theme.typography.inter.fontFamily,
        fontSize: 14,
        fontWeight: 'bolder',
        letterSpacing: '-0.46px',
        lineHeight: '26px',
        textAlign: 'left',
    },
    Button: {
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 1,
        textAlign: 'center',
        marginTop: theme.spacing(1),
        width: '289px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '48px',
        },
    },
    title: {
        color: theme.appColors.primaryBlue,
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        fontSize: '38px',
        fontWeight: '700',
        lineHeight: '48px',
        letterSpacing: '-0.01em',

        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
            lineHeight: '32px',
        },
    },
    gridContainerDescription: {
        marginTop: theme.spacing(4),
    },
    description: {
        maxWidth: 660,
        color: theme.appColors.darkGray,
        fontFamily: theme.typography.bancoDoBrasilTextos.fontFamily,
        fontSize: '16px',
        lineHeight: '18px',
    },
    underlineText: {
        height: 5,
        width: 57,
        backgroundColor: theme.appColors.secondaryColor,
        borderRadius: 32,
        marginTop: theme.spacing(2),
    },
    gridContainer: {
        padding: theme.spacing(6, 6, 4),
        maxWidth: '1440px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(5, 2, 4),
        },
    },
}));
