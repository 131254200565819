import { yupResolver } from '@hookform/resolvers';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomInputForm from '../../components/CustomInputForm/CustomInputForm';
import ModalErroPrimeiroAcesso from '../../components/ModalErroPrimeiroAcesso/ModalErroPrimeiroAcesso';
import {
    FORM_DADOS_PESSOAIS as fpText,
    FORM_DADOS_PESSOAIS_FIELDS as fpFields,
} from '../../consts/text';
import PRIMEIRO_ACESSO from '../../consts/text/primeiro-acesso';
import { AutenticationActions } from '../../store/ducks/autentication';
import { masks } from '../../utils';
import { formSchemaPrimeiroAcesso } from './form-schema';
import { useStyles } from './styles';

export default function FormPrimeiroAcesso() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { errors, register, handleSubmit, getValues } = useForm({
        resolver: yupResolver(formSchemaPrimeiroAcesso),
    });

    const submitForm = (data) => {
        dispatch(
            AutenticationActions.getConsultaExistenciaDocumentoRequest({
                tipoPessoa: 'PJ',
                ...data,
            }),
        );
    };

    return (
        <>
            <Grid item xs={12}>
                <CustomInputForm
                    name={fpFields.CPF_CNPJ}
                    label={fpText.CNPJ}
                    inputRef={register}
                    errors={errors}
                    shrink={Boolean(getValues(fpFields.CPF_CNPJ))}
                    inputProps={{
                        mask: masks.cnpjMask,
                        showMask: false,
                        inputMode: 'numeric',
                    }}
                    checkValid
                    data-gtm-form='input'
                    data-gtm-name='cnpj'
                />
            </Grid>

            <Grid item xs={12} container justifyContent="center">
                <CustomButton
                    className={classes.Button}
                    content={PRIMEIRO_ACESSO.BUTTON}
                    variant="contained"
                    onClickCallBack={handleSubmit(submitForm)}
                />
            </Grid>
            <ModalErroPrimeiroAcesso />
        </>
    );
}
