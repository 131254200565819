import { ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';

export const appColors = {
    primaryColor: '#465EFF',
    footerBlue: '#465EFF',
    footerSecondaryColor: '#465EFF',
    bgBlue: '#3751FF',
    footerDarkBlue: '#3751FF',
    primaryBlue: '#5A6FFF',
    secondaryBlue: '#5A6FFF',
    blue: '#0089ec',
    darkBlue: '#2D5A9C',
    lighterBlue: '#0053A1',
    cardBorder: '#CAD0F8',
    cardBorderInative: '#DDE4FC',
    lightBlue: '#0a67a2',
    greyTerciary: '#00375f',
    purpleText: '#8891CC',
    purpleborder: '#BCB3FF',
    terciaryBlue: '#95B9D9',
    secondaryColor: '#FCFC30',
    primaryYellow: '#FCEE4F',
    secondaryYellow: '#FFFA72',
    footerPrimaryColor: '#FAE22C',
    errorRed: '#f44336',
    white: '#ffff',
    whiteColor: '#FFFFFF',
    primaryTextColor: 'rgba(255,255,255,.9)',
    primaryHeaderColor: '#edf0f4',
    backGroundColorPrimary: '#F3EEFB',
    newGreySecondary: '#F9F7FF',
    lightGreySecondary: '#f2f2f2',
    secondaryLightTextColor: '#ccc',
    cardGray: '#4f5d6a',
    lightGreyTerciary: '#B0B0B0',
    strongGrey: '#979797',
    newGrey: '#4D4D4D',
    darkGray: '#4C4C4C',
    grey: '#333333',
    shadowGrey: '#A3A3A3',
    greySecondary: '#4F4F4F',
    gray: '#4E4E4E',
    mediumGray: '#C0D1DD',
    lightGray: '#757575',
    alternativeGray: '#DDD',
    secondaryColorContrastText: '#2e2e2e',
    lightGrey: 'rgba(0, 0, 0, 0.54)',
    lightBlack: 'rgba(0, 0, 0, 0.85)',
    black: '#000000',
    lightPurple: '#DDE4FC',
    dotsLightGray: '#D9D9D9',
    dotsDarkGray: '#949494',
};

const AppTheme = createTheme(
    {
        palette: {
            primary: {
                main: appColors.primaryColor,
                contrastText: appColors.primaryTextColor,
            },
            secondary: {
                main: appColors.secondaryColor,
                contrastText: appColors.secondaryColorContrastText,
            },

            text: {
                primary: appColors.secondaryColorContrastText,
                secondary: appColors.primaryColor,
                disabled: appColors.secondaryLightTextColor,
            },
        },
        appColors,
        typography: {
            fontFamily: [
                'BancoDoBrasilTextos',
                'BancoDoBrasilTitulos',
                'Inter',
            ].join(','),
            body1: {
                fontFamily: 'BancoDoBrasilTextos',
            },
            subtitle1: {
                fontFamily: 'BancoDoBrasilTextos',
            },
            bancoDoBrasilTitulos: {
                fontFamily: 'BancoDoBrasilTitulos',
            },
            bancoDoBrasilTextos: {
                fontFamily: 'BancoDoBrasilTextos',
            },
            inter: {
                fontFamily: 'Inter',
            },
        },
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&:-webkit-autofill': {
                            backgroundClip: 'text',
                        },
                        color: 'red !important',
                    },
                },
            },
        },
        overrides: {
            MuiOutlinedInput: {
                input: {
                    '&:-webkit-autofill': {
                        '-webkit-box-shadow': '0 0 0 100px #fff inset',
                    },
                },
            },
        },
    },
    ptBR,
);

export default AppTheme;
