import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll !important',
        height: '100% !important',
        zIndex: 99999,

        '& > div': {
            [theme.breakpoints.up('sm')]: { marginRight: 14 },
        },
    },
    containerModal: ({ isCobertura, applyMargin, isResumo }) => ({
        width: '100%',
        padding: theme.spacing(3),
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: '10px',
        top: isCobertura ? 30 : 'auto',
        minWidth: 480,
        [theme.breakpoints.down('sm')]: {
            marginBottom: isCobertura || applyMargin ? 300 : 0,
        },

        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
            borderRadius: 0,
            top: isCobertura ? 0 : 'auto',
        },

        '&:focus': {
            outline: 'none !important',
        },

        '&::after': isCobertura && !isResumo && {
            content: "''",
            display: 'block',
            marginBottom: 150,
        },
    }),
    title: {
        fontSize: 24,
        fontFamily: theme.typography.bancoDoBrasilTitulos.fontFamily,
        color: theme.appColors.purpleText,
        width: '100%',
        lineHeight: '28px',
        fontWeight: 'bold',
        margin: theme.spacing(2, 0),
        textAlign: 'center',
    },
    subTitle: {
        fontSize: 16,
        color: theme.appColors.darkGray,
        fontFamily: theme.typography.inter.fontFamily,
        marginBottom: 16,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px',
        letterSpacing: '-0.01px',
        textAlign: 'center',
    },
    description: {
        fontSize: 16,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.5',
        letterSpacing: '0.1px',
        textAlign: 'center',
    },
    closeIcon: {
        position: 'absolute',
        backgroundColor: '#F9F7FF',
        border: '0.5px solid #DDE4FC',
        borderRadius: '100%',
        color: theme.palette.primary.main,
        fontSize: 18,
        padding: 5,
        top: 10,
        right: 10,
        cursor: 'pointer',

        [theme.breakpoints.down('xs')]: {
            top: 20,
            right: 25,
        },
    },
    actions: {
        marginTop: theme.spacing(3),
    },

    fadeModal: {},
}));
