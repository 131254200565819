//#region Imports

import { createStyles, makeStyles } from "@material-ui/core/styles";

//#endregion

export const useStyles = () => {
    const styles = makeStyles((theme) =>
        createStyles({
            "@keyframes rotate": {
                "0%": {
                    transform: "rotate(0deg)",
                },
                "100%": {
                    transform: "rotate(360deg)",
                },
            },
            backdrop: {
                zIndex: theme.zIndex.drawer + 1,
                background: "none",
                color: "#FFFFFF",
            },
            logoBorder: {
                position: "relative",
                overflow: "hidden",
                padding: 4,
                zIndex: 1,
                "& img": {
                    width: 114,
                    height: 114,
                    display: "block",
                },
                "&:after": {
                    content: "''",
                    position: "absolute",
                    zIndex: -1,
                    width: 200,
                    height: 200,
                    top: -35,
                    left: -35,
                    backgroundImage: "linear-gradient(#c4edff,#015ba6 60%)",
                    animationName: "$rotate",
                    animationDuration: "3s",
                    animationTimingFunction: "linear",
                    animationIterationCount: "infinite",
                },
            },
        })
    );

    return styles();
};

export default useStyles;
