import FIELDS from '../../../../library/acesso-dados-fields';
import appYup from '../../../../library/validation/validation';

const {
    EMAIL,
    CORRENSPONDENCIA,
    OUTRAS_SOLICITACOES: { VALOR_ELIMINAR, VALOR_BLOQUEAR },
} = FIELDS;

const formOutrasSolicitacoesSchema = appYup.object().shape(
    {
        [EMAIL]: appYup.string().when(CORRENSPONDENCIA, {
            is: (val) => !val,
            then: appYup.string().required().email(),
            otherwise: appYup.string().email(),
        }),
        [CORRENSPONDENCIA]: appYup.string().when(EMAIL, {
            is: (val) => !val,
            then: appYup.string().required().max(400),
            otherwise: appYup.string().max(400),
        }),
        [VALOR_ELIMINAR]: appYup.string().max(400),
        [VALOR_BLOQUEAR]: appYup.string().max(400),
    },
    [EMAIL, CORRENSPONDENCIA]
);

export default formOutrasSolicitacoesSchema;
